import { useContext } from 'react'
import { useIntl } from 'react-intl'
import { EOrgType } from '@unfoldrtech/portal-mic'
import {
  AdGroupContext,
  AppContext,
  CampaignContext,
  EDatasetKey,
} from '../../../models'
import { TDatasetWhitelistKey, TGlobalTables } from '../models'
import { TGlobalTableDataType } from '../models/types/global-table-data.type'
import { EReportColumnHeader } from '../../../models/core'
import { useFeatureFlags } from '../../../hooks/useFeatureFlags'
import { PORTAL_FF_REPORTING_METRICS_OMIT } from '../../../utils/constants'
import {
  genericMetricHeaders,
  spMetricHeaders,
  daMetricHeaders,
  doohMetricHeaders,
  daKeywordMetricHeaders,
  daProductMetricHeaders,
  spProductMetricHeaders,
  genericAdgroupSearchMetricHeaders,
  spKeywordMetricHeaders,
  daAudienceMetricHeaders,
  doohAudienceMetricHeaders,
  genericAdvertiserMetricHeaders,
} from './constants'
import { isCurrency } from '../helpers'

export type TReportTableEntity =
  | 'ad.groups'
  | 'advertiser'
  | 'audiences'
  | 'campaigns'
  | 'categories'
  | 'positions'
  | 'locations'
  | 'keywords'
  | 'products'
  | 'creatives'

export const useGetDatasetKeyTableWhitelist = ({
  key,
}: {
  key: TDatasetWhitelistKey
}) => {
  const intl = useIntl()
  const featureFlags = useFeatureFlags()

  const [appContext] = useContext(AppContext)
  const campaign = useContext(CampaignContext)
  const adGroup = useContext(AdGroupContext)

  const orgType = appContext.userOrg!.type

  const whitelist = {} as Record<
    TDatasetWhitelistKey,
    Array<TGlobalTableDataType>
  >

  const getGlobalTableDataType = ({
    header,
    entity,
  }: {
    header: TGlobalTables
    entity?: TReportTableEntity
  }): TGlobalTableDataType => {
    return {
      header,
      translatedMetricName: intl.formatMessage({
        id: `report.table.header.${orgType?.toLowerCase()}.${entity ?? header}`,
      }),
      translatedTooltipMetricName: intl.formatMessage({
        id: `report.table.header.${orgType?.toLowerCase()}.${
          entity ?? header
        }.tooltip`,
      }),
      isCurrency: isCurrency(header),
    }
  }

  const getTableWhitelist = (
    headers: Array<TGlobalTables>,
    entity: TReportTableEntity
  ) => {
    return headers.map((header) =>
      getGlobalTableDataType({
        header,
        entity: header === EReportColumnHeader.Name ? entity : undefined,
      })
    )
  }

  whitelist[EDatasetKey.Advertiser] = getTableWhitelist(
    [
      EReportColumnHeader.Name,
      EReportColumnHeader.Status,
      EReportColumnHeader.PermitStatus,
      ...genericAdvertiserMetricHeaders,
    ],
    'advertiser'
  )

  whitelist[EDatasetKey.CampaignGeneric] = getTableWhitelist(
    [
      EReportColumnHeader.Name,
      EReportColumnHeader.CampaignType,
      EReportColumnHeader.AdvertiserName,
      EReportColumnHeader.Status,
      EReportColumnHeader.ChannelType,
      EReportColumnHeader.Budget,
      EReportColumnHeader.StartDate,
      EReportColumnHeader.EndDate,
      ...genericMetricHeaders,
    ],
    'campaigns'
  )
  whitelist[EDatasetKey.CampaignSp] = getTableWhitelist(
    [
      EReportColumnHeader.Name,
      EReportColumnHeader.CampaignType,
      EReportColumnHeader.AdvertiserName,
      EReportColumnHeader.Status,
      EReportColumnHeader.ChannelType,
      EReportColumnHeader.Budget,
      EReportColumnHeader.StartDate,
      EReportColumnHeader.EndDate,
      ...spMetricHeaders,
    ],
    'campaigns'
  )
  whitelist[EDatasetKey.CampaignDa] = getTableWhitelist(
    [
      EReportColumnHeader.Name,
      EReportColumnHeader.CampaignType,
      EReportColumnHeader.AdvertiserName,
      EReportColumnHeader.Status,
      EReportColumnHeader.ChannelType,
      EReportColumnHeader.Budget,
      EReportColumnHeader.StartDate,
      EReportColumnHeader.EndDate,
      ...daMetricHeaders,
    ],
    'campaigns'
  )
  whitelist[EDatasetKey.CampaignDooh] = getTableWhitelist(
    [
      EReportColumnHeader.Name,
      EReportColumnHeader.CampaignType,
      EReportColumnHeader.AdvertiserName,
      EReportColumnHeader.Status,
      EReportColumnHeader.ChannelType,
      EReportColumnHeader.Budget,
      EReportColumnHeader.StartDate,
      EReportColumnHeader.EndDate,
      ...doohMetricHeaders,
    ],
    'campaigns'
  )

  whitelist[EDatasetKey.AdgroupGeneric] = getTableWhitelist(
    [
      EReportColumnHeader.Name,
      EReportColumnHeader.Status,
      EReportColumnHeader.InventoryType,
      EReportColumnHeader.ChannelType,
      ...genericMetricHeaders,
    ],
    'ad.groups'
  )
  whitelist[EDatasetKey.AdgroupGenericCategory] = getTableWhitelist(
    [
      EReportColumnHeader.Name,
      EReportColumnHeader.Status,
      EReportColumnHeader.ChannelType,
      ...genericMetricHeaders,
    ],
    'ad.groups'
  )
  whitelist[EDatasetKey.AdgroupGenericKeyword] = getTableWhitelist(
    [
      EReportColumnHeader.Name,
      EReportColumnHeader.Status,
      EReportColumnHeader.ChannelType,
      ...genericAdgroupSearchMetricHeaders, // exception
    ],
    'ad.groups'
  )
  whitelist[EDatasetKey.AdgroupDaPagePosition] = getTableWhitelist(
    [
      EReportColumnHeader.Name,
      EReportColumnHeader.Status,
      EReportColumnHeader.ChannelType,
      ...daMetricHeaders,
    ],
    'ad.groups'
  )
  whitelist[EDatasetKey.AdgroupDoohScreen] = getTableWhitelist(
    [
      EReportColumnHeader.Name,
      EReportColumnHeader.Status,
      EReportColumnHeader.ChannelType,
      EReportColumnHeader.ScreenPositionName,
      EReportColumnHeader.BidPrice,
      ...doohMetricHeaders,
    ],
    'ad.groups'
  )

  whitelist[EDatasetKey.KeywordSp] = getTableWhitelist(
    [
      EReportColumnHeader.Name,
      EReportColumnHeader.Campaigns,
      EReportColumnHeader.Adgroups,
      ...spKeywordMetricHeaders, // exception
    ],
    'keywords'
  )
  whitelist[EDatasetKey.KeywordDa] = getTableWhitelist(
    [
      EReportColumnHeader.Name,
      EReportColumnHeader.Campaigns,
      EReportColumnHeader.Adgroups,
      ...daKeywordMetricHeaders, // exception
    ],
    'keywords'
  )

  whitelist[EDatasetKey.CategorySp] = getTableWhitelist(
    [
      EReportColumnHeader.Name,
      EReportColumnHeader.Campaigns,
      EReportColumnHeader.Adgroups,
      ...spMetricHeaders,
    ],
    'categories'
  )
  whitelist[EDatasetKey.CategoryDa] = getTableWhitelist(
    [
      EReportColumnHeader.Name,
      EReportColumnHeader.Campaigns,
      EReportColumnHeader.Adgroups,
      ...daMetricHeaders,
    ],
    'categories'
  )

  whitelist[EDatasetKey.ProductSp] = getTableWhitelist(
    [
      EReportColumnHeader.Image,
      EReportColumnHeader.Name,
      EReportColumnHeader.Campaigns,
      EReportColumnHeader.Adgroups,
      EReportColumnHeader.InStock,
      ...spProductMetricHeaders, // exception
    ],
    'products'
  )
  whitelist[EDatasetKey.ProductDa] = getTableWhitelist(
    [
      EReportColumnHeader.Image,
      EReportColumnHeader.Name,
      EReportColumnHeader.Campaigns,
      EReportColumnHeader.Adgroups,
      EReportColumnHeader.InStock,
      ...daProductMetricHeaders, // exception
    ],
    'products'
  )

  whitelist[EDatasetKey.AdgroupSp] = getTableWhitelist(
    [
      EReportColumnHeader.Name,
      EReportColumnHeader.Status,
      EReportColumnHeader.InventoryType,
      ...spMetricHeaders,
    ],
    'ad.groups'
  )
  whitelist[EDatasetKey.AdgroupDa] = getTableWhitelist(
    [
      EReportColumnHeader.Name,
      EReportColumnHeader.Status,
      EReportColumnHeader.InventoryType,
      ...daMetricHeaders,
    ],
    'ad.groups'
  )
  whitelist[EDatasetKey.AdgroupDooh] = getTableWhitelist(
    [
      EReportColumnHeader.Name,
      EReportColumnHeader.Status,
      EReportColumnHeader.ScreenPositionName,
      EReportColumnHeader.BidPrice,
      ...doohMetricHeaders,
    ],
    'ad.groups'
  )

  whitelist[EDatasetKey.CreativeDa] = getTableWhitelist(
    [
      EReportColumnHeader.Creative,
      EReportColumnHeader.Adgroup,
      EReportColumnHeader.Status,
      EReportColumnHeader.Placement,
      EReportColumnHeader.PlatformType,
      ...daMetricHeaders,
    ],
    'creatives'
  )
  whitelist[EDatasetKey.CreativeDooh] = getTableWhitelist(
    [
      EReportColumnHeader.Creative,
      EReportColumnHeader.Adgroup,
      EReportColumnHeader.Status,
      EReportColumnHeader.Placement,
      ...doohMetricHeaders,
    ],
    'creatives'
  )

  whitelist[EDatasetKey.PositionDa] = getTableWhitelist(
    [
      EReportColumnHeader.Name,
      EReportColumnHeader.Adgroups,
      ...daMetricHeaders,
    ],
    'positions'
  )
  whitelist[EDatasetKey.AudienceDa] = getTableWhitelist(
    [
      EReportColumnHeader.Name,
      EReportColumnHeader.Adgroups,
      EReportColumnHeader.Campaigns,
      EReportColumnHeader.Positions,
      ...daAudienceMetricHeaders, // exception
    ],
    'audiences'
  )
  whitelist[EDatasetKey.AudienceDooh] = getTableWhitelist(
    [
      EReportColumnHeader.Name,
      EReportColumnHeader.Adgroups,
      EReportColumnHeader.Campaigns,
      EReportColumnHeader.Positions,
      ...doohAudienceMetricHeaders, // exception
    ],
    'audiences'
  )

  whitelist[EDatasetKey.ProductSpAdgroup] = getTableWhitelist(
    [
      EReportColumnHeader.Image,
      EReportColumnHeader.Name,
      EReportColumnHeader.Status,
      EReportColumnHeader.InStock,
      ...spProductMetricHeaders, // exception
    ],
    'products'
  )
  whitelist[EDatasetKey.ProductDaAdgroup] = getTableWhitelist(
    [
      EReportColumnHeader.Image,
      EReportColumnHeader.Name,
      EReportColumnHeader.Status,
      EReportColumnHeader.InStock,
      ...daProductMetricHeaders, // exception
    ],
    'products'
  )

  whitelist[EDatasetKey.CategorySpAdgroup] = getTableWhitelist(
    [
      EReportColumnHeader.Name,
      EReportColumnHeader.Status,
      EReportColumnHeader.BidPrice,
      ...spMetricHeaders,
    ],
    'categories'
  )
  whitelist[EDatasetKey.CategoryDaAdgroup] = getTableWhitelist(
    [
      EReportColumnHeader.Name,
      EReportColumnHeader.Status,
      EReportColumnHeader.BidPrice,
      ...daMetricHeaders,
    ],
    'categories'
  )

  whitelist[EDatasetKey.KeywordSpAdgroup] = getTableWhitelist(
    [
      EReportColumnHeader.Name,
      EReportColumnHeader.Status,
      EReportColumnHeader.MatchType,
      EReportColumnHeader.BidPrice,
      ...spKeywordMetricHeaders, // exception
    ],
    'keywords'
  )
  whitelist[EDatasetKey.KeywordDaAdgroup] = getTableWhitelist(
    [
      EReportColumnHeader.Name,
      EReportColumnHeader.Status,
      EReportColumnHeader.MatchType,
      EReportColumnHeader.BidPrice,
      ...daKeywordMetricHeaders, // exception
    ],
    'keywords'
  )
  whitelist[EDatasetKey.PositionDaAdgroupPagePosition] = getTableWhitelist(
    [
      EReportColumnHeader.Name,
      EReportColumnHeader.Status,
      EReportColumnHeader.BidPrice,
      ...daMetricHeaders,
    ],
    'positions'
  )
  whitelist[EDatasetKey.PositionDaAdgroupCategory] = getTableWhitelist(
    [EReportColumnHeader.Name, EReportColumnHeader.Status, ...daMetricHeaders],
    'positions'
  )
  whitelist[EDatasetKey.PositionDaAdgroupKeyword] = getTableWhitelist(
    [EReportColumnHeader.Name, EReportColumnHeader.Status, ...daMetricHeaders],
    'positions'
  )
  whitelist[EDatasetKey.AudienceDaAdgroup] = getTableWhitelist(
    [
      EReportColumnHeader.Name,
      EReportColumnHeader.Status,
      EReportColumnHeader.Positions,
      ...daAudienceMetricHeaders, // exception
    ],
    'audiences'
  )
  whitelist[EDatasetKey.AudienceDoohAdgroup] = getTableWhitelist(
    [
      EReportColumnHeader.Name,
      EReportColumnHeader.Status,
      EReportColumnHeader.Positions,
      ...doohAudienceMetricHeaders, // exception,
    ],
    'audiences'
  )
  whitelist[EDatasetKey.LocationDooh] = getTableWhitelist(
    [
      EReportColumnHeader.Name,
      EReportColumnHeader.Type,
      EReportColumnHeader.AdgroupName,
      ...doohMetricHeaders,
    ],
    'locations'
  )

  whitelist[EDatasetKey.CreativeDaAdgroup] = getTableWhitelist(
    [
      EReportColumnHeader.Creative,
      EReportColumnHeader.Status,
      EReportColumnHeader.Placement,
      EReportColumnHeader.PlatformType,
      ...daMetricHeaders,
    ],
    'creatives'
  )
  whitelist[EDatasetKey.CreativeDoohAdgroup] = getTableWhitelist(
    [
      EReportColumnHeader.Creative,
      EReportColumnHeader.Status,
      EReportColumnHeader.Placement,
      ...doohMetricHeaders,
    ],
    'creatives'
  )

  if (adGroup.id && key === EDatasetKey.LocationDooh) {
    whitelist[EDatasetKey.LocationDooh] = whitelist[
      EDatasetKey.LocationDooh
    ].filter((column) => column.header !== EReportColumnHeader.AdgroupName)
  }
  if (adGroup.id && key === EDatasetKey.AudienceDa) {
    whitelist[EDatasetKey.AudienceDa] = whitelist[
      EDatasetKey.AudienceDa
    ].filter((column) => column.header !== EReportColumnHeader.AdgroupName)
  }
  if (campaign.id && key === EDatasetKey.ProductSp) {
    whitelist[EDatasetKey.ProductSp] = whitelist[EDatasetKey.ProductSp].filter(
      (column) => column.header !== EReportColumnHeader.Campaigns
    )
  }
  if (adGroup.id && key === EDatasetKey.ProductSp) {
    whitelist[EDatasetKey.ProductSp] = whitelist[EDatasetKey.ProductSp].filter(
      (column) => column.header !== EReportColumnHeader.Adgroups
    )
  }
  if ((!campaign.id || !adGroup.id) && key === EDatasetKey.ProductSp) {
    whitelist[EDatasetKey.ProductSp] = whitelist[EDatasetKey.ProductSp].filter(
      (column) => column.header !== EReportColumnHeader.Status
    )
    whitelist[EDatasetKey.ProductSp] = whitelist[EDatasetKey.ProductSp].filter(
      (column) => column.header !== EReportColumnHeader.AvgRank
    )
  }
  if (campaign.id && key === EDatasetKey.ProductDa) {
    whitelist[EDatasetKey.ProductDa] = whitelist[EDatasetKey.ProductDa].filter(
      (column) => column.header !== EReportColumnHeader.Campaigns
    )
  }
  if (adGroup.id && key === EDatasetKey.ProductDa) {
    whitelist[EDatasetKey.ProductDa] = whitelist[EDatasetKey.ProductDa].filter(
      (column) => column.header !== EReportColumnHeader.Adgroups
    )
  }
  if ((!campaign.id || !adGroup.id) && key === EDatasetKey.ProductDa) {
    whitelist[EDatasetKey.ProductDa] = whitelist[EDatasetKey.ProductDa].filter(
      (column) => column.header !== EReportColumnHeader.Status
    )
  }
  if (orgType === EOrgType.Advertiser && key === EDatasetKey.CampaignGeneric) {
    whitelist[EDatasetKey.CampaignGeneric] = whitelist[
      EDatasetKey.CampaignGeneric
    ].filter((column) => column.header !== EReportColumnHeader.AdvertiserName)
  }
  if (orgType === EOrgType.Advertiser && key === EDatasetKey.CampaignSp) {
    whitelist[EDatasetKey.CampaignSp] = whitelist[
      EDatasetKey.CampaignSp
    ].filter((column) => column.header !== EReportColumnHeader.AdvertiserName)
  }
  if (orgType === EOrgType.Advertiser && key === EDatasetKey.CampaignDa) {
    whitelist[EDatasetKey.CampaignDa] = whitelist[
      EDatasetKey.CampaignDa
    ].filter((column) => column.header !== EReportColumnHeader.AdvertiserName)
  }
  if (orgType === EOrgType.Advertiser && key === EDatasetKey.CampaignDooh) {
    whitelist[EDatasetKey.CampaignDooh] = whitelist[
      EDatasetKey.CampaignDooh
    ].filter((column) => column.header !== EReportColumnHeader.AdvertiserName)
  }

  if (
    featureFlags[PORTAL_FF_REPORTING_METRICS_OMIT] &&
    featureFlags[PORTAL_FF_REPORTING_METRICS_OMIT].length
  ) {
    whitelist[key] = whitelist[key].filter(
      (column) =>
        !featureFlags[PORTAL_FF_REPORTING_METRICS_OMIT].includes(column.header)
    )
  }

  return whitelist[key]
}
