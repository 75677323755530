import { useMutation } from 'react-query'
import { AxiosError, AxiosResponse } from 'axios'
import { IError } from '@unfoldrtech/portal-mic'
import {
  IDOOHAdGroup,
  IDOOHAdGroupUpdate,
} from '@unfoldrtech/portal-mic/dist/src/models/dooh'

import { coreAPIV3 } from '../services/coreAPIV3'
import { generateCorrelationId } from '../utils/helpers'

export default function usePatchDoohAdGroup(params: {
  advertiserId: number
  retailerId: number
}) {
  return useMutation<
    AxiosResponse<IDOOHAdGroup>,
    AxiosError<IError>,
    {
      payload: IDOOHAdGroupUpdate & { id: number }
    }
  >([`patchDoohAdGroup_${params.advertiserId}`], ({ payload }) => {
    const { id, ...restPayload } = payload

    return coreAPIV3
      .doohAdvertiserCampaigns()
      .patchDoohAdGroup(
        params.retailerId,
        params.advertiserId,
        id,
        generateCorrelationId(),
        restPayload
      )
  })
}
