import { EInventoryType, EPageType } from '@unfoldrtech/portal-mic'

export function getPageTypeFromInventoryType(
  inventoryType: EInventoryType
): EPageType | undefined {
  switch (inventoryType) {
    case EInventoryType.PagePosition:
      return EPageType.Home
    case EInventoryType.Category:
      return EPageType.Categories
    case EInventoryType.Keyword:
      return EPageType.Search
    default:
      return undefined
  }
}
