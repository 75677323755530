import {
  TDAAdGroupsCreativesReportTableFilters,
  TDACreativesReportTableFilters,
  TDOOHAdGroupsCreativesReportTableFilters,
  TDOOHCreativesReportTableFilters,
} from '../../../models/types'

type TCreativesTableReportingFilters =
  | TDACreativesReportTableFilters
  | TDAAdGroupsCreativesReportTableFilters
  | TDOOHCreativesReportTableFilters
  | TDOOHAdGroupsCreativesReportTableFilters

export const selectCreativesTableReportingFilters = ({
  reportingFilters: reportingCreativesTableFiltersState,
}: {
  reportingFilters: TCreativesTableReportingFilters
}): TCreativesTableReportingFilters => reportingCreativesTableFiltersState
