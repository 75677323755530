import React from 'react'
import { useGetFormattedDate } from '../ReportPageWrapperV3/hooks/use-get-formatted-date'

// This is an override to recharts which says props is any
export const CustomizedTick = ({
  tickFormatter,
  visibleTicksCount,
  verticalAnchor,
  ...props
}: any) => {
  const tickLabel = useGetFormattedDate({ date: props.payload.value })
  return <text {...props}>{tickLabel}</text>
}
