import { EReportColumnHeader } from '../../../../../models/core'
import { TGlobalTables } from '../../../models'

export const doohMetricHeaders: Array<TGlobalTables> = [
  EReportColumnHeader.Playouts,
  EReportColumnHeader.Impressions,
  EReportColumnHeader.Costs,
  EReportColumnHeader.Cpm,
  EReportColumnHeader.ShareOfVoice,
  EReportColumnHeader.WinRate,
  // EReportColumnHeader.AvgRank, // not available yet
]
