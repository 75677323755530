import React, { useState, useContext, useEffect } from 'react'
import { EInventoryType } from '@unfoldrtech/portal-mic'

import usePatchAdGroup from '../../../../hooks/usePatchAdGroup'
import { useGetRetailerAndAdvertiserIds } from '../../hooks/use-get-retailer-and-advertiser-ids'
import { getAdGroupTargetList } from '../../../../store/campaigns'
import {
  EOrgType,
  EStatus,
  TTranslationKey,
  AdGroupContext,
  IAdGroup,
} from '../../../../models'
import { TableDataCellBidPriceContainer } from './TableDataCellBidPriceContainer'

export const TableDataCellBidPrice = ({
  dataId,
  orgType,
  status,
  setErrorUpdate,
  setShowUpdateErrorToast,
  dataValue,
  floorPrice,
}: {
  dataId: number
  orgType: EOrgType
  status: EStatus
  setErrorUpdate: (error: TTranslationKey) => void
  setShowUpdateErrorToast: (show: boolean) => void
  dataValue?: number
  floorPrice?: number
}) => {
  const [bidPrice, setBidPrice] = useState<number | undefined>(dataValue)

  const adGroup = useContext(AdGroupContext)

  const { retailerId, advertiserId } = useGetRetailerAndAdvertiserIds()

  const {
    mutate: mutateAdGroup,
    reset: resetMutateAdGroup,
    isLoading: isUpdating,
  } = usePatchAdGroup({
    advertiserId,
    retailerId,
    adGroupId: adGroup?.id,
  })

  const handleKeywordBidPriceChange = (keywordId: number, bid: number) => {
    const updatedAdGroup = {
      inventoryType: EInventoryType.Keyword,
      keywordsList: adGroup.keywordsList.map((k) =>
        k.keywordId === keywordId ? { ...k, bidPrice: bid } : k
      ),
      positionsList: adGroup.positionsList,
    }

    return updatedAdGroup
  }

  const handlePositionBidPriceChange = (positionId: number, bid: number) => {
    const updatedAdGroup = {
      inventoryType: EInventoryType.PagePosition,
      positionsList: adGroup.positionsList?.map((p) =>
        p.id === positionId ? { ...p, bidPrice: bid } : p
      ),
    }

    return updatedAdGroup
  }

  const handleCategoryBidPriceChange = (categoryId: number, bid: number) => {
    const updatedAdGroup = {
      inventoryType: EInventoryType.Category,
      categoriesList: adGroup.categoriesList?.map((p) =>
        p.id === categoryId ? { ...p, bidPrice: bid } : p
      ),
      positionsList: adGroup.positionsList,
    }

    return updatedAdGroup
  }

  const handleBidPriceSubmit = (bid: number) => {
    let updatedAdGroupTargeting = {}
    const payload = {
      targeting: {},
    }

    if (adGroup.inventoryType === EInventoryType.Keyword) {
      updatedAdGroupTargeting = handleKeywordBidPriceChange(dataId, bid)
    } else if (adGroup.inventoryType === EInventoryType.Category) {
      updatedAdGroupTargeting = handleCategoryBidPriceChange(dataId, bid)
    } else if (adGroup.inventoryType === EInventoryType.PagePosition) {
      updatedAdGroupTargeting = handlePositionBidPriceChange(dataId, bid)
    }
    resetMutateAdGroup()

    payload.targeting = getAdGroupTargetList(
      updatedAdGroupTargeting as IAdGroup
    )

    mutateAdGroup(
      { payload },
      {
        onSuccess: () => {
          setBidPrice(bid)
        },
        onError: (error) => {
          setShowUpdateErrorToast(true)
          setErrorUpdate(
            error?.response?.data.errorKey as unknown as TTranslationKey
          )
        },
      }
    )
  }

  useEffect(() => {
    if (dataValue !== undefined) {
      setBidPrice(dataValue)
    }
  }, [dataValue])

  return (
    <TableDataCellBidPriceContainer
      bidPrice={bidPrice}
      orgType={orgType}
      floorPrice={floorPrice}
      status={status}
      isUpdating={isUpdating}
      onSubmit={handleBidPriceSubmit}
    />
  )
}
