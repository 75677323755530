import { useQuery } from 'react-query'
import { AxiosError, AxiosResponse } from 'axios'
import { IError } from '@unfoldrtech/portal-mic'
import { generateCorrelationId, getTimezone } from '../utils/helpers'
import { TAdvertisersReportTableFilters, TPPRetailerId } from '../models/types'
import { EReportingTimeWindow } from '../models'
import {
  EReportColumnHeader,
  IReportingAdvertisersTableResponse,
} from '../models/core'
import { coreAPIV3 } from '../services/coreAPIV3'

export default function useGetRetailerReportingAdvertisersTableV3({
  retailerId,
  advertiserId,
  sortBy,
  timeWindow,
  startDate,
  endDate,
  sortOrder,
  tzZone,
  enabled = true,
  suspense = true,
}: {
  enabled?: boolean
  suspense?: boolean
} & TAdvertisersReportTableFilters &
  TPPRetailerId) {
  const queryKeyName = `getRetailerReportingAdvertisersTable_${retailerId}`

  return useQuery<
    AxiosResponse<IReportingAdvertisersTableResponse>,
    AxiosError<IError>
  >(
    [queryKeyName, { start: startDate, end: endDate }],
    () => {
      return coreAPIV3
        .retailerReporting()
        .getRetailerAdvertisersReportTable(
          retailerId,
          generateCorrelationId(),
          advertiserId,
          sortBy as EReportColumnHeader,
          sortOrder,
          timeWindow as EReportingTimeWindow,
          startDate,
          endDate,
          tzZone || getTimezone()
        )
    },
    { suspense, enabled }
  )
}
