import { EReportColumnHeader } from '../../../../../models/core'
import { TGlobalTables } from '../../../models'
import { doohMetricHeaders } from './dooh-metric-headers'

export const doohAudienceMetricHeaders: Array<TGlobalTables> =
  doohMetricHeaders.filter(
    (header) =>
      !(
        header === EReportColumnHeader.ShareOfVoice ||
        header === EReportColumnHeader.WinRate
      )
  )
