import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'

import { useGetRetailerAndAdvertiserIds } from '../../hooks/use-get-retailer-and-advertiser-ids'
import { EOrgType, EStatus, TTranslationKey } from '../../../../models'
import usePatchDoohAdGroup from '../../../../hooks/usePatchDoohAdGroup'
import { selectDoohCampaignAdGroups } from '../../../../store/doohCampaign'
import { TableDataCellBidPriceContainer } from './TableDataCellBidPriceContainer'

export const TableDataCellAdGroupBidPrice = ({
  dataValue,
  floorPrice,
  dataId,
  orgType,
  status,
  setErrorUpdate,
  setShowUpdateErrorToast,
}: {
  dataValue?: number
  floorPrice?: number
  dataId: number
  orgType: EOrgType
  status: EStatus
  setErrorUpdate: (error: TTranslationKey) => void
  setShowUpdateErrorToast: (show: boolean) => void
}) => {
  const [bidPrice, setBidPrice] = useState<number | undefined>(dataValue)

  const doohCampaignAdGroups = useSelector(selectDoohCampaignAdGroups)

  const { retailerId, advertiserId } = useGetRetailerAndAdvertiserIds()

  const {
    mutate: mutateDoohAdGroup,
    reset: resetMutateDoohAdGroup,
    isLoading: isUpdatingDoohAdGroup,
  } = usePatchDoohAdGroup({
    advertiserId,
    retailerId,
  })

  const handleBidPriceSubmit = (bid: number) => {
    const currentAdGroup = doohCampaignAdGroups.find((dag) => dag.id === dataId)
    const updatedAdGroupTargeting = {
      ...currentAdGroup!.targeting,
      screenPosition: {
        ...currentAdGroup!.targeting.screenPosition,
        bidPrice: Number(bid),
      },
    }

    const payload = {
      id: dataId,
      targeting: updatedAdGroupTargeting,
    }

    resetMutateDoohAdGroup()

    mutateDoohAdGroup(
      { payload },
      {
        onSuccess: () => {
          setBidPrice(bid)
        },
        onError: (error) => {
          setShowUpdateErrorToast(true)
          setErrorUpdate(
            error?.response?.data.errorKey as unknown as TTranslationKey
          )
        },
      }
    )
  }

  useEffect(() => {
    if (dataValue !== undefined) {
      setBidPrice(dataValue)
    }
  }, [dataValue])

  return (
    <TableDataCellBidPriceContainer
      bidPrice={bidPrice}
      orgType={orgType}
      floorPrice={floorPrice}
      status={status}
      isUpdating={isUpdatingDoohAdGroup}
      onSubmit={handleBidPriceSubmit}
    />
  )
}
