import { useQuery } from 'react-query'
import { AxiosError, AxiosResponse } from 'axios'
import { IError } from '@unfoldrtech/portal-mic'
import { generateCorrelationId, getTimezone } from '../utils/helpers'
import {
  TDACampaignsReportTableFilters,
  TDOOHCampaignsReportTableFilters,
  TGenericCampaignsReportTableFilters,
  TPPAdvertiserId,
  TSPCampaignsReportTableFilters,
} from '../models/types'
import { EReportingTimeWindow } from '../models'
import {
  EParamChannelTypeGlobal,
  EReportColumnHeader,
  IReportingCampaignsTableResponse,
} from '../models/core'
import { coreAPIV3 } from '../services/coreAPIV3'

export default function useGetAdvertiserReportingCampaignsTableV3({
  advertiserId,
  retailerId,
  channelType,
  sortBy,
  timeWindow,
  startDate,
  endDate,
  sortOrder,
  tzZone,
  enabled = true,
  suspense = true,
}: {
  enabled?: boolean
  suspense?: boolean
} & (
  | TGenericCampaignsReportTableFilters
  | TSPCampaignsReportTableFilters
  | TDACampaignsReportTableFilters
  | TDOOHCampaignsReportTableFilters
) &
  TPPAdvertiserId) {
  const queryKeyName = `getAdvertiserReportingCampaignsTable_${channelType}_${advertiserId}_${retailerId}`

  return useQuery<
    AxiosResponse<IReportingCampaignsTableResponse>,
    AxiosError<IError>
  >(
    [queryKeyName, { start: startDate, end: endDate, channelType }],
    () => {
      return coreAPIV3
        .advertiserReporting()
        .getAdvertiserCampaignsReportTableV3(
          advertiserId,
          generateCorrelationId(),
          retailerId,
          channelType as EParamChannelTypeGlobal,
          sortBy as EReportColumnHeader,
          sortOrder,
          timeWindow as unknown as EReportingTimeWindow,
          startDate,
          endDate,
          tzZone || getTimezone()
        )
    },
    { suspense, enabled }
  )
}
