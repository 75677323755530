import * as XLSX from 'xlsx'
import { saveAs } from 'file-saver'

import {
  TReportExportType,
  IDOOHReportLocationTableRow,
  IDAReportAudienceTableRow,
  ISPReportProductsTableRow,
  IDAReportProductsTableRow,
} from '../../../models'
import { exportBlobTypeMap } from '../../../utils/constants'
import { TGlobalTableDataType } from '../models'
import {
  IDAReportCampaignsTableRow,
  IDAReportKeywordsTableRow,
  IDAReportCategoriesTableRow,
  IDAReportPositionsTableRow,
  IDOOHReportAudiencesTableRow,
  IDOOHReportCampaignsTableRow,
  IGenericReportCampaignsTableRow,
  ISPReportCampaignsTableRow,
  ISPReportKeywordsTableRow,
  ISPReportCategoriesTableRow,
  IDAReportAdGroupsPositionsTableRow,
  IDAReportAdGroupsTableRow,
  IDOOHReportAdGroupsScreensTableRow,
  IDOOHReportAdGroupsTableRow,
  IGenericReportAdGroupsCategoriesTableRow,
  IGenericReportAdGroupsKeywordsTableRow,
  IGenericReportAdGroupsTableRow,
  ISPReportAdGroupsTableRow,
  IReportAdvertisersTableRow,
  IDAReportCreativesTableRow,
  IDAReportAdGroupsCreativesTableRow,
  IDOOHReportAdGroupsCreativesTableRow,
  IDOOHReportCreativesTableRow,
} from '../../../models/core'

type TReportTableRow =
  | IDAReportAudienceTableRow
  | IDOOHReportAudiencesTableRow
  | IDOOHReportLocationTableRow
  | ISPReportProductsTableRow
  | IDAReportProductsTableRow
  | IDAReportPositionsTableRow
  | IGenericReportCampaignsTableRow
  | ISPReportCampaignsTableRow
  | IDAReportCampaignsTableRow
  | IDOOHReportCampaignsTableRow
  | ISPReportKeywordsTableRow
  | IDAReportKeywordsTableRow
  | IDAReportCategoriesTableRow
  | ISPReportCategoriesTableRow
  | IGenericReportAdGroupsTableRow
  | IGenericReportAdGroupsCategoriesTableRow
  | IGenericReportAdGroupsKeywordsTableRow
  | ISPReportAdGroupsTableRow
  | IDAReportAdGroupsTableRow
  | IDAReportAdGroupsPositionsTableRow
  | IDOOHReportAdGroupsTableRow
  | IDOOHReportAdGroupsScreensTableRow
  | IReportAdvertisersTableRow
  | IDAReportCreativesTableRow
  | IDAReportAdGroupsCreativesTableRow
  | IDOOHReportCreativesTableRow
  | IDOOHReportAdGroupsCreativesTableRow

export const exportTableDataToFile = ({
  tableDataRows,
  tableHeaders,
  fileName,
  fileType,
}: {
  tableDataRows: Array<TReportTableRow>
  tableHeaders: Array<TGlobalTableDataType>
  fileName: string
  fileType: TReportExportType
}) => {
  // transform table data into csv/excel
  const data: Array<Record<string, unknown>> =
    tableDataRows?.map((rowData) => {
      const transformedRowData: Record<string, unknown> = {}
      tableHeaders.forEach((headerObject) => {
        const header = headerObject.header as keyof TReportTableRow
        const translatedHeader = headerObject.translatedMetricName
        const cellData = rowData[header]
        transformedRowData[translatedHeader] = cellData
      })
      return transformedRowData
    }) || []

  // export
  const blobType = exportBlobTypeMap[fileType] ?? exportBlobTypeMap.csv
  const worksheet = XLSX.utils.json_to_sheet(data, { dense: true })
  const workbook = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(workbook, worksheet)

  // Buffer to store the generated Excel file
  const workbookBuffer = XLSX.write(workbook, {
    bookType: fileType,
    type: 'array',
    compression: true,
  })
  const blob = new Blob([workbookBuffer], {
    type: blobType,
  })

  saveAs(blob, `${fileName}.${fileType}`)
}
