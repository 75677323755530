import React, { useContext, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useIntl } from 'react-intl'
import dayjs from 'dayjs'

import { ReportPageTemplateLayout } from '../../components/ReportPageTemplateLayout'
import { ReportingAdvertisersChartWrapper } from './ReportingAdvertisersChartWrapper'
import { TReportExportType, TReportingFilters } from '../../../../models'
import { AppContext } from '../../../../models/contexts'
import { setIsReportingV3 } from '../../../../store/reportingFilters'
import {
  NOT_AVAILABLE,
  exportFileDateFormat,
} from '../../../../utils/constants'
import { exportTableDataToFile } from '../../helpers'
import { useGetDatasetKeyTableWhitelist } from '../../hooks/use-get-dataset-key-table-whitelist'
import { ReportingAdvertisersTableWrapper } from './ReportingAdvertisersTableWrapper'
import { EDatasetKey, IReportAdvertisersTable } from '../../../../models/core'
import { TDatasetWhitelistKey } from '../../models'

export const ReportingAdvertisersTemplate = ({
  isHome,
}: {
  isHome?: boolean
}) => {
  let pageTitle = 'reporting.header.advertisers'
  pageTitle = isHome ? 'home' : pageTitle

  const intl = useIntl()
  const dispatch = useDispatch()

  const [appContext] = useContext(AppContext)

  const [tableData] = useState<IReportAdvertisersTable>()

  const whitelistData = useGetDatasetKeyTableWhitelist({
    key:
      (tableData?.datasetKey as TDatasetWhitelistKey) || EDatasetKey.Advertiser,
  })

  const onExportData = (
    { startDate: start, endDate: end }: TReportingFilters,
    type: TReportExportType
  ) => {
    const orgName =
      appContext.userOrg?.name ?? appContext.userOrg?.type ?? NOT_AVAILABLE
    const translatedPageTitle = intl
      .formatMessage({ id: pageTitle })
      .replace(/[-]+/g, '')
      .replace(/[ ]+/g, '_')
    const sDate = dayjs(start).format(exportFileDateFormat)
    const eDate = dayjs(end).format(exportFileDateFormat)

    const fileName = `${orgName}_${translatedPageTitle}_${sDate}_${eDate}`
    if (tableData) {
      exportTableDataToFile({
        tableDataRows: tableData.rows,
        tableHeaders: whitelistData,
        fileName,
        fileType: type,
      })
    }
  }

  useEffect(() => {
    dispatch(setIsReportingV3({ IsReportingV3: true }))
  }, [])

  return (
    <>
      <ReportPageTemplateLayout
        title={pageTitle}
        isExportDisabled={Boolean(!tableData?.rows.length)}
        chartWrapper={<ReportingAdvertisersChartWrapper />}
        tableWrapper={<ReportingAdvertisersTableWrapper isHome={isHome} />}
        onExportData={onExportData}
      />
    </>
  )
}
