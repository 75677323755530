import { EReportColumnHeader } from '../../../../../models/core'
import { TGlobalTables } from '../../../models'
import { spMetricHeaders } from './sp-metric-headers'

export const spKeywordMetricHeaders: Array<TGlobalTables> =
  spMetricHeaders.filter(
    (header) =>
      !(
        header === EReportColumnHeader.ShareOfVoice ||
        header === EReportColumnHeader.WinRate
      )
  )
