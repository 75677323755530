import React, { useState, useEffect, ReactNode, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  EEntityType,
  EParamChannelType,
  EParamSortOrder,
  EStatus,
} from '@unfoldrtech/portal-mic'
import { TReportingFilters } from '../../../../models/types'
import { StyledImage } from '../../../Global'
import { selectAudienceTableReportingFilters } from '../../../../store/reportingFilters'
import {
  selectRefreshTable,
  setRefreshTable,
} from '../../../../store/refreshTable'
import { ReportTableV3 } from '../../components/ReportTableV3'
import { getPlaceholderImage } from '../../helpers/get-placeholder-image'
import { TableDataCellName } from '../../components/table-data-cell/TableDataCellName'
import { TableDataCellImpressions } from '../../components/table-data-cell/TableDataCellImpressions'
import { TableDataCellCosts } from '../../components/table-data-cell/TableDataCellCosts'
import { TableDataCellCpm } from '../../components/table-data-cell/TableDataCellCpm'
import { useGetRetailerAndAdvertiserIds } from '../../hooks/use-get-retailer-and-advertiser-ids'
import {
  AppContext,
  AdGroupContext,
  CampaignContext,
  IDAReportAudiencesTable,
} from '../../../../models'
import { TReportTableDataV3 } from '../../models/types/report-table-data-v3.type'
import { useGetDatasetKeyTableWhitelist } from '../../hooks/use-get-dataset-key-table-whitelist'
import { TDAAudienceTableHeaders, TDOOHAudiencesTableHeaders } from '../models'
import { getTableAndChartAudiencesFn } from '../helpers/get-table-and-chart-audiences-fn'
import { TableDataCellCtr } from '../../components/table-data-cell/TableDataCellCtr'
import { TableDataCellUniqueReach } from '../../components/table-data-cell/TableDataCellUniqueReach'
import {
  TDatasetWhitelistKey,
  TGlobalTableDataType,
  TGlobalTables,
} from '../../models'
import { TableDataCellFrequency } from '../../components/table-data-cell/TableDataCellFrequency'
import { TablePreloader } from '../../components/table-preloader'
import {
  EReportColumnHeader,
  IDOOHReportAudiencesTable,
} from '../../../../models/core'
import { convertEParamChannelTypeToEChannelTypeGlobal } from '../../../../utils/helpers'
import { convertEChannelTypeToEChannelTypeGlobal } from '../../../../utils/helpers/convert-e-channel-type-to-e-channel-type-global'
import { getAudienceEDatasetKeyFromProps } from '../helpers/get-audience-e-dataset-key-from-props'
import { TableDataCellStatusAudiencesWithUpdate } from '../../components/table-data-cell/TableDataCellStatusAudiencesWithUpdate'
import { TableDataCellCampaigns } from '../../components/table-data-cell/TableDataCellCampaigns'
import { TableDataCellAdGroups } from '../../components/table-data-cell/TableDataCellAdGroups'
import { TableDataCellPositions } from '../../components/table-data-cell/TableDataCellPositions'
import { TableDataCellCarts } from '../../components/table-data-cell/TableDataCellCarts'
import { TableDataCellPurchases } from '../../components/table-data-cell/TableDataCellPurchases'
import { TableDataCellRevenue } from '../../components/table-data-cell/TableDataCellRevenue'
import { TableDataCellRoas } from '../../components/table-data-cell/TableDataCellRoas'
import { TableDataCellPlayouts } from '../../components/table-data-cell/TableDataCellPlayouts'
import { useGetDefaultSortBy } from '../../hooks/use-get-default-sort-by'
import { TableDataCellShareOfVoice } from '../../components/table-data-cell/TableDataCellShareOfVoice'
import { TableDataCellWinRate } from '../../components/table-data-cell/TableDataCellWinRate'

type TAudiencesTableHeaders = TDAAudienceTableHeaders &
  TDOOHAudiencesTableHeaders
type IReportAudiencesTable = IDAReportAudiencesTable | IDOOHReportAudiencesTable
export const ReportingAudienceTableWrapper = ({
  onTableDataChange,
}: {
  onTableDataChange: (audiencesTableData: IReportAudiencesTable) => void
}) => {
  const dispatch = useDispatch()

  const [appContext] = useContext(AppContext)
  const campaign = useContext(CampaignContext)
  const adGroup = useContext(AdGroupContext)

  const {
    startDate,
    endDate,
    timeWindow,
    tzZone,
    channelType: reportingFilterChannelType,
  } = useSelector(selectAudienceTableReportingFilters)
  const channelType =
    convertEParamChannelTypeToEChannelTypeGlobal(
      reportingFilterChannelType as EParamChannelType
    ) || convertEChannelTypeToEChannelTypeGlobal(campaign.channelType)
  const refreshTable = useSelector(selectRefreshTable)

  const { retailerId, advertiserId } = useGetRetailerAndAdvertiserIds()

  const orgType = appContext.userOrg!.type
  const hookFn = getTableAndChartAudiencesFn({
    orgType,
  })

  const getTableDataFn = hookFn.tableFn!

  const campaignId = campaign?.id || -1
  const adGroupId = adGroup?.id

  const [sortBy, setSortBy] = useState<TAudiencesTableHeaders>()
  const [sortOrder, setSortOrder] = useState<EParamSortOrder>()
  const [tableData, setTableData] = useState<TReportTableDataV3>()

  useGetDefaultSortBy({
    sortBy,
    sortOrder,
    setSortBy,
    setSortOrder,
    orgType,
    channelType,
  })

  const {
    data: tableDataResponseObj,
    refetch,
    isFetching,
  } = getTableDataFn({
    retailerId,
    advertiserId,
    campaignId,
    adGroupId,
    channelType,
    sortBy,
    timeWindow,
    startDate,
    endDate,
    sortOrder,
    tzZone,
    enabled: false,
  })

  const onSortByChange = (sortColumn?: TGlobalTables) => {
    setSortBy(sortColumn as TAudiencesTableHeaders)
  }

  const onSortOrderChange = (
    type: TReportingFilters['sortOrder'] = EParamSortOrder.Desc
  ) => {
    setSortOrder(type)
  }

  const tableDataResponse =
    tableDataResponseObj?.data.table || ({} as IReportAudiencesTable)

  const whitelistDataList = useGetDatasetKeyTableWhitelist({
    key:
      (tableDataResponse.datasetKey as TDatasetWhitelistKey) ||
      getAudienceEDatasetKeyFromProps(channelType, !!adGroupId),
  })

  const processTableDataResponse = (tableDataRes: IReportAudiencesTable) => {
    const responseHeaders = Array.from(
      new Set(tableDataRes.rows.map((row) => Object.keys(row)).flat())
    )

    const whitelistData = whitelistDataList
      .map((wData) => {
        const whitelistItem = responseHeaders.find(
          (header) => wData.header === header
        )
        if (whitelistItem) {
          return wData
        }
        return undefined
      })
      .filter((wData) => wData) as Array<TGlobalTableDataType>

    const headers = whitelistData.map((wData) => wData.header)

    const processedTableData: TReportTableDataV3 = {
      columnIdList: headers,
      columnNameList: whitelistData.map((wData) => wData.translatedMetricName),
      columnNameTooltipList: whitelistData.map(
        (wData) => wData.translatedTooltipMetricName
      ),
      columnSpanList: headers.map((header) =>
        header === EReportColumnHeader.Name ? 2 : 1
      ),
      rowList: [],
    }

    tableDataRes.rows.forEach((row) => {
      const dataRow: Array<ReactNode | ReactNode[]> = []
      headers.forEach((header) => {
        let dataCell: ReactNode | ReactNode[]
        const dataValue = row[header as TAudiencesTableHeaders]

        switch (header) {
          case EReportColumnHeader.Name:
            dataCell = <TableDataCellName dataValue={dataValue?.toString()} />
            break
          case EReportColumnHeader.Status:
            dataCell = (
              <TableDataCellStatusAudiencesWithUpdate
                dataValue={dataValue?.toString() as EStatus}
                orgType={orgType}
                id={row.id}
                key={`${row.id}-${sortBy}-${sortOrder}`}
              />
            )
            break
          case EReportColumnHeader.Campaigns:
            dataCell = (
              <TableDataCellCampaigns dataValue={dataValue as Array<string>} />
            )
            break
          case EReportColumnHeader.Adgroups:
            dataCell = (
              <TableDataCellAdGroups dataValue={dataValue as Array<string>} />
            )
            break
          case EReportColumnHeader.Positions:
            dataCell = (
              <TableDataCellPositions dataValue={dataValue as Array<string>} />
            )
            break
          case EReportColumnHeader.Impressions:
            dataCell = (
              <TableDataCellImpressions dataValue={Number(dataValue)} />
            )
            break
          case EReportColumnHeader.Costs:
            dataCell = <TableDataCellCosts dataValue={Number(dataValue)} />
            break
          case EReportColumnHeader.Cpm:
            dataCell = <TableDataCellCpm dataValue={Number(dataValue)} />
            break
          // DA Exclusive
          case EReportColumnHeader.Clicks:
            dataCell = (
              <TableDataCellImpressions dataValue={Number(dataValue)} />
            )
            break
          case EReportColumnHeader.Ctr:
            dataCell = <TableDataCellCtr dataValue={Number(dataValue)} />
            break
          case EReportColumnHeader.UniqueReach:
            dataCell = (
              <TableDataCellUniqueReach dataValue={Number(dataValue)} />
            )
            break
          case EReportColumnHeader.Frequency:
            dataCell = <TableDataCellFrequency dataValue={Number(dataValue)} />
            break
          case EReportColumnHeader.Carts:
            dataCell = <TableDataCellCarts dataValue={Number(dataValue)} />
            break
          case EReportColumnHeader.PvCarts:
            dataCell = <TableDataCellCarts dataValue={Number(dataValue)} />
            break
          case EReportColumnHeader.Purchases:
            dataCell = <TableDataCellPurchases dataValue={Number(dataValue)} />
            break
          case EReportColumnHeader.PvPurchases:
            dataCell = <TableDataCellPurchases dataValue={Number(dataValue)} />
            break
          case EReportColumnHeader.Revenue:
            dataCell = <TableDataCellRevenue dataValue={Number(dataValue)} />
            break
          case EReportColumnHeader.PvRevenue:
            dataCell = <TableDataCellRevenue dataValue={Number(dataValue)} />
            break
          case EReportColumnHeader.Roas:
            dataCell = <TableDataCellRoas dataValue={Number(dataValue)} />
            break
          case EReportColumnHeader.PvRoas:
            dataCell = <TableDataCellRoas dataValue={Number(dataValue)} />
            break
          // DOOH Exclusive
          case EReportColumnHeader.Playouts:
            dataCell = <TableDataCellPlayouts dataValue={Number(dataValue)} />
            break
          case EReportColumnHeader.ShareOfVoice:
            dataCell = (
              <TableDataCellShareOfVoice dataValue={Number(dataValue)} />
            )
            break
          case EReportColumnHeader.WinRate:
            dataCell = <TableDataCellWinRate dataValue={Number(dataValue)} />
            break
          default:
            dataCell = undefined
            break
        }

        if (dataCell) {
          dataRow.push(dataCell)
        }
      })

      processedTableData.rowList.push(dataRow)
    })

    return processedTableData
  }

  useEffect(() => {
    if (startDate && endDate && sortBy && sortOrder) {
      refetch()
    }
  }, [startDate, endDate, sortBy, sortOrder])

  useEffect(() => {
    if (refreshTable) {
      refetch()
      dispatch(setRefreshTable({ refreshTable: false }))
    }
  }, [refreshTable])

  useEffect(() => {
    if (Object.keys(tableDataResponse).length > 0) {
      setTableData(processTableDataResponse(tableDataResponse))
      onTableDataChange(tableDataResponse)
    }
  }, [tableDataResponse])

  return (
    <>
      {tableData?.rowList?.length &&
        tableData?.rowList?.length > 0 &&
        !isFetching && (
          <>
            {tableData?.rowList && (
              <ReportTableV3
                {...tableData}
                sortBy={sortBy}
                sortOrder={sortOrder}
                setSortBy={onSortByChange}
                setSortOrder={onSortOrderChange}
              />
            )}
          </>
        )}
      {tableData?.rowList?.length === 0 && (
        <StyledImage
          fluid
          src={getPlaceholderImage(EEntityType.AdGroupAudiences, orgType)}
          alt="home_placeholder"
          cursor="default"
        />
      )}
      {isFetching && <TablePreloader />}
    </>
  )
}
