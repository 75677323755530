import { EDatasetKey, EChannelTypeGlobal } from '../../../../models/core'

export function getCategoriesEDatasetKeyFromChannelTypeGlobal(
  channelType?: EChannelTypeGlobal
) {
  switch (channelType) {
    case EChannelTypeGlobal.SponsoredProduct:
      return EDatasetKey.CategorySp
    case EChannelTypeGlobal.DisplayAd:
      return EDatasetKey.CategoryDa
    default: // eventually we should add generic and throw error if other
      return EDatasetKey.CategorySp
  }
}
