import { EReportColumnHeader } from '../../../../../models/core'
import { TGlobalMetrics } from '../../../models'
import { genericMetricNames } from './generic-metric-names'

export const genericAdgroupSearchMetricNames: Array<TGlobalMetrics> =
  genericMetricNames.filter(
    (header) =>
      !(
        header === EReportColumnHeader.ShareOfVoice ||
        header === EReportColumnHeader.WinRate
      )
  )
