import { EReportColumnHeader } from '../../../../../models/core'
import { TGlobalMetrics } from '../../../models'
import { spMetricNames } from './sp-metric-names'

export const spProductMetricNames: Array<TGlobalMetrics> = spMetricNames.filter(
  (header) =>
    !(
      header === EReportColumnHeader.ShareOfVoice ||
      header === EReportColumnHeader.WinRate ||
      header === EReportColumnHeader.AvgRank
    )
)
