import { TGlobalTables } from '../../../models'
import { genericMetricHeaders } from './generic-metric-headers'
import { EReportColumnHeader } from '../../../../../models/core'

export const genericAdvertiserMetricHeaders: Array<TGlobalTables> =
  genericMetricHeaders.filter(
    (header) =>
      !(
        header === EReportColumnHeader.ShareOfVoice ||
        header === EReportColumnHeader.WinRate
      )
  )
