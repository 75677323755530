import { EReportColumnHeader } from '../../../../../models/core'
import { TGlobalTables } from '../../../models'
import { daMetricHeaders } from './da-metric-headers'

export const daKeywordMetricHeaders: Array<TGlobalTables> =
  daMetricHeaders.filter(
    (header) =>
      !(
        header === EReportColumnHeader.ShareOfVoice ||
        header === EReportColumnHeader.WinRate
      )
  )
