import { ISelectCategoryOption } from '../../models'
import { sanitizeDataTestId } from '../sanitizeDataTestId'
import { ICategoryWithParents } from '../../models/core'

export const getCategoryWithParentSelectOptions = (
  categories: ICategoryWithParents[]
): Array<ISelectCategoryOption> => {
  return categories.map((cat) => {
    const label = cat.parent?.title
      ? `${cat.parent?.title} / ${cat.title}`
      : cat.title

    return {
      id: cat.id,
      fullLabel: label,
      label: cat.title,
      dataTestId: sanitizeDataTestId(`option-${cat.title}`),
      value: cat.id.toString(),
      parentId: cat.parent?.id,
      parentLabel: cat.parent?.title,
    } as ISelectCategoryOption
  })
}
