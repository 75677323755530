import { EReportColumnHeader } from '../../../../../models/core'
import { TGlobalTables } from '../../../models'
import { daMetricHeaders } from './da-metric-headers'

export const daAdgroupMetricHeaders: Array<TGlobalTables> =
  daMetricHeaders.filter(
    (header) =>
      !(
        header === EReportColumnHeader.Carts ||
        header === EReportColumnHeader.Purchases ||
        header === EReportColumnHeader.Revenue ||
        header === EReportColumnHeader.Roas
      )
  )
