import { EUserRole } from '@unfoldrtech/portal-mic'
import { TReportSidebarItem } from '../../models/types'
import { IAppBarProps } from '../../models/interfaces'

export const ADVERTISER_OVERVIEW_SIDEBAR_ELEMENTS: Array<TReportSidebarItem> = [
  {
    label: 'advertiser.nav.campaigns',
    type: 'header',
  },
  {
    label: 'all',
    type: 'item',
    url: 'campaigns-all',
  },
  {
    label: 'retailer.inventory.nav.sponsoredProducts',
    type: 'item',
    url: 'campaigns-sp',
  },
  {
    label: 'retailer.inventory.nav.displayAds',
    type: 'item',
    url: 'campaigns-da',
  },
  {
    label: 'retailer.inventory.nav.digital.out.of.home',
    type: 'item',
    url: 'campaigns-dooh',
  },
  {
    label: 'advertiser.campaigns.new.review.ad.groups',
    type: 'header',
  },
  {
    label: 'all',
    type: 'item',
    url: 'adgroups-all',
  },
  {
    label: 'retailer.nav.inventory.categories',
    type: 'item',
    url: 'adgroups-categories',
  },
  {
    label: 'retailer.nav.inventory.search',
    type: 'item',
    url: 'adgroups-search',
  },
  {
    label: 'retailer.nav.inventory.pagePosition',
    type: 'item',
    url: 'adgroups-pagePosition',
  },
  {
    label: 'retailer.nav.inventory.screen',
    type: 'item',
    url: 'adgroups-screen',
  },
  {
    label: 'advertiser.nav.totals',
    type: 'header',
  },
  {
    label: 'retailer.inventory.nav.sponsoredProducts',
    type: 'subHeader',
    subItems: [
      {
        label: 'advertiser.nav.products',
        type: 'item',
        url: 'products-sp',
      },
      {
        label: 'advertiser.nav.keywords',
        type: 'item',
        url: 'keywords-sp',
      },
      {
        label: 'advertiser.nav.categories',
        type: 'item',
        url: 'categories-sp',
      },
    ],
  },
  {
    label: 'retailer.inventory.nav.displayAds',
    type: 'subHeader',
    subItems: [
      {
        label: 'advertiser.nav.products',
        type: 'item',
        url: 'products-da',
      },
      {
        label: 'advertiser.nav.keywords',
        type: 'item',
        url: 'keywords-da',
      },
      {
        label: 'advertiser.nav.categories',
        type: 'item',
        url: 'categories-da',
      },
    ],
  },
]

/**
 * Each element should be processed by the page
 * Disable settings page if campaign has started
 */
export const ADVERTISER_CAMPAIGN_SIDEBAR_ELEMENTS: Array<TReportSidebarItem> = [
  {
    label: 'advertiser.nav.campaign',
    type: 'header',
  },
  {
    label: 'advertiser.campaigns.new.review.ad.groups',
    type: 'item',
    url: 'adgroups',
  },
  {
    label: 'advertiser.nav.creatives',
    type: 'item',
    url: 'creatives-da',
  },
  {
    label: 'advertiser.nav.creatives',
    type: 'item',
    url: 'creatives-dooh',
  },
  {
    label: 'advertiser.nav.products',
    type: 'item',
    url: 'products',
  },
  {
    label: 'advertiser.nav.positions',
    type: 'item',
    url: 'positions',
  },
  {
    label: 'advertiser.nav.audiences',
    type: 'item',
    url: 'audiences',
  },
  {
    label: 'settings',
    type: 'item',
    url: 'settings',
    role: EUserRole.AdvertiserCampaigns,
  },
]

/**
 * Each element should be processed by the page
 * Disable settings page if campaign has started
 */
export const ADVERTISER_ADGROUPS_SIDEBAR_ELEMENTS: Array<TReportSidebarItem> = [
  {
    label: 'advertiser.nav.adgroup',
    type: 'header',
  },
  {
    label: 'retailer.advertisers.nav.products',
    type: 'item',
    url: 'products',
  },
  {
    label: 'retailer.advertisers.nav.creatives',
    type: 'item',
    url: 'creatives-da',
  },
  {
    label: 'retailer.advertisers.nav.creatives',
    type: 'item',
    url: 'creatives-dooh',
  },
  {
    label: 'retailer.advertisers.nav.positions',
    type: 'item',
    url: 'positions',
  },
  {
    label: 'retailer.nav.inventory.categories',
    type: 'item',
    url: 'categories',
  },
  {
    label: 'retailer.nav.inventory.keywords',
    type: 'item',
    url: 'keywords',
  },
  {
    label: 'retailer.nav.inventory.search.terms',
    type: 'item',
    url: 'search-terms',
  },
  // disabled until implememntation of pagePosition reporting PORT-713
  // {
  //   label: 'retailer.nav.inventory.pagePosition',
  //   type: 'item',
  //   url: 'pagePosition',
  // },
  {
    label: 'advertiser.nav.locations',
    type: 'item',
    url: 'locations',
  },
  {
    label: 'advertiser.nav.audiences',
    type: 'item',
    url: 'audiences',
  },
  {
    label: 'settings',
    type: 'item',
    url: 'settings',
    role: EUserRole.AdvertiserCampaigns,
  },
]

export const ADVERTISER_DA_CREATIVES_APPBAR_ELEMENTS: IAppBarProps = {
  navLinkItems: [
    {
      title: 'advertiser.nav.display.ads',
      path: 'display-ads',
    },
  ],
}

export const ADVERTISER_DOOH_CREATIVES_APPBAR_ELEMENTS: IAppBarProps = {
  navLinkItems: [
    {
      title: 'advertiser.nav.dooh',
      path: 'dooh',
    },
  ],
}

export const ADVERTISER_ALL_CREATIVES_APPBAR_ELEMENTS: IAppBarProps = {
  navLinkItems: [
    ...ADVERTISER_DA_CREATIVES_APPBAR_ELEMENTS.navLinkItems!,
    ...ADVERTISER_DOOH_CREATIVES_APPBAR_ELEMENTS.navLinkItems!,
  ],
}
export const RETAILER_CREATIVES_APPBAR_ELEMENTS: IAppBarProps = {
  navLinkItems: [
    {
      title: 'retailer.nav.display.ads',
      path: 'display-ads',
    },
    {
      title: 'retailer.nav.dooh',
      path: 'dooh',
    },
  ],
}
