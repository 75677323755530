import React, { useState, useEffect, ReactNode, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  EEntityType,
  EOrgType,
  EParamAdvertiserTargetType,
  EParamSortOrder,
  EStatus,
  IPermittedPositionsResponse,
  ITotalItems,
} from '@unfoldrtech/portal-mic'
import { TReportingFilters, TTranslationKey } from '../../../../models/types'
import {
  EChannelTypeGlobal,
  EReportColumnHeader,
} from '../../../../models/core'
import { StyledImage } from '../../../Global'
import { selectDAPositionsTableReportingFilters } from '../../../../store/reportingFilters'
import {
  selectRefreshTable,
  setRefreshTable,
} from '../../../../store/refreshTable'
import { ReportTableV3 } from '../../components/ReportTableV3'
import { getPlaceholderImage } from '../../helpers/get-placeholder-image'
import { TableDataCellName } from '../../components/table-data-cell/TableDataCellName'
import { TableDataCellStatus } from '../../components/table-data-cell/TableDataCellStatus'
import { TableDataCellImpressions } from '../../components/table-data-cell/TableDataCellImpressions'
import { TableDataCellCosts } from '../../components/table-data-cell/TableDataCellCosts'
import { TableDataCellCpm } from '../../components/table-data-cell/TableDataCellCpm'
import { useGetRetailerAndAdvertiserIds } from '../../hooks/use-get-retailer-and-advertiser-ids'
import {
  AppContext,
  AdGroupContext,
  CampaignContext,
  EDatasetKey,
  IDAReportPositionsTable,
} from '../../../../models'
import { TReportTableDataV3 } from '../../models/types/report-table-data-v3.type'
import { useGetDatasetKeyTableWhitelist } from '../../hooks/use-get-dataset-key-table-whitelist'
import { TDAPositionsTableHeaders } from '../models'
import { getTableAndChartDAPositionsFn } from '../helpers/get-table-and-chart-da-positions-fn'
import { TableDataCellCtr } from '../../components/table-data-cell/TableDataCellCtr'
import { TableDataCellUniqueReach } from '../../components/table-data-cell/TableDataCellUniqueReach'
import {
  TDatasetWhitelistKey,
  TGlobalTableDataType,
  TGlobalTables,
} from '../../models'
import { TableDataCellShareOfVoice } from '../../components/table-data-cell/TableDataCellShareOfVoice'
import { TableDataCellFrequency } from '../../components/table-data-cell/TableDataCellFrequency'
import { TableDataCellAdGroups } from '../../components/table-data-cell/TableDataCellAdGroups'
import { TableDataCellCarts } from '../../components/table-data-cell/TableDataCellCarts'
import { TableDataCellBidPrice } from '../../components/table-data-cell/TableDataCellBidPrice'
import { TableDataCellWinRate } from '../../components/table-data-cell/TableDataCellWinRate'
import { TableDataCellPurchases } from '../../components/table-data-cell/TableDataCellPurchases'
import { TableDataCellRevenue } from '../../components/table-data-cell/TableDataCellRevenue'
import { TableDataCellRoas } from '../../components/table-data-cell/TableDataCellRoas'
import { TablePreloader } from '../../components/table-preloader'
import { useGetDefaultSortBy } from '../../hooks/use-get-default-sort-by'
import { selectCampaignAdGroups } from '../../../../store/campaigns'
import useGetAdvertiserTargets from '../../../../hooks/useGetAdvertiserTargets'
import { ErrorToast } from '../../../ToastCard/ErrorToast'
import useShowToast from '../../../../hooks/useShowToast'

export const ReportingDAPositionsTableWrapper = ({
  onTableDataChange,
}: {
  onTableDataChange: (positionsTableData: IDAReportPositionsTable) => void
}) => {
  const dispatch = useDispatch()

  const [appContext] = useContext(AppContext)
  const campaign = useContext(CampaignContext)
  const adGroup = useContext(AdGroupContext)

  const orgType = appContext.userOrg!.type
  const { retailerId, advertiserId } = useGetRetailerAndAdvertiserIds()

  const campaignAdGroups = useSelector(selectCampaignAdGroups)
  const currentAdGroup = campaignAdGroups.filter((a) => a.id === adGroup.id)[0]

  // Fetch permitted Positions
  const { data: permittedPositionsResponse } = useGetAdvertiserTargets({
    advertiserId,
    retailerId,
    targetType: EParamAdvertiserTargetType.Position,
    enabled: orgType === EOrgType.Advertiser,
  })

  const permittedPositionsData =
    (
      permittedPositionsResponse?.data as ITotalItems &
        IPermittedPositionsResponse
    )?.positions || []

  const getPositionFloorPrice = (positionId: number) => {
    const hasAudiencesTargeted = !!currentAdGroup?.audiencesList?.length
    const position = permittedPositionsData.find((p) => p.id === positionId)

    return hasAudiencesTargeted && position?.audienceFloorPrice
      ? position.audienceFloorPrice
      : position?.floorPrice
  }

  const { startDate, endDate, timeWindow, tzZone } = useSelector(
    selectDAPositionsTableReportingFilters
  )
  const refreshTable = useSelector(selectRefreshTable)

  const hookFn = getTableAndChartDAPositionsFn({
    orgType,
  })

  const getTableDataFn = hookFn.tableFn!

  const campaignId = campaign?.id || -1
  const adGroupId = adGroup?.id

  const [sortBy, setSortBy] = useState<TDAPositionsTableHeaders>()
  const [sortOrder, setSortOrder] = useState<EParamSortOrder>()
  const [tableData, setTableData] = useState<TReportTableDataV3>()

  const [errorUpdate, setErrorUpdate] = useState<TTranslationKey>()
  const [showUpdateErrorToast, setShowUpdateErrorToast] = useShowToast(false)

  useGetDefaultSortBy({
    sortBy,
    sortOrder,
    setSortBy,
    setSortOrder,
    orgType,
    channelType: EChannelTypeGlobal.DisplayAd,
  })

  const {
    data: tableDataResponseObj,
    refetch,
    isFetching,
  } = getTableDataFn({
    retailerId,
    advertiserId,
    campaignId,
    adGroupId,
    sortBy,
    timeWindow,
    startDate,
    endDate,
    sortOrder,
    tzZone,
    enabled: false,
  })

  const onSortByChange = (sortColumn?: TGlobalTables) => {
    setSortBy(sortColumn as TDAPositionsTableHeaders)
  }

  const onSortOrderChange = (
    type: TReportingFilters['sortOrder'] = EParamSortOrder.Desc
  ) => {
    setSortOrder(type)
  }

  const tableDataResponse =
    tableDataResponseObj?.data.table || ({} as IDAReportPositionsTable)

  const whitelistDataList = useGetDatasetKeyTableWhitelist({
    key:
      (tableDataResponse.datasetKey as TDatasetWhitelistKey) ||
      EDatasetKey.PositionDa,
  })

  const processTableDataResponse = (tableDataRes: IDAReportPositionsTable) => {
    const responseHeaders = Array.from(
      new Set(tableDataRes.rows.map((row) => Object.keys(row)).flat())
    )

    const whitelistData = whitelistDataList
      .map((wData) => {
        const whitelistItem = responseHeaders.find(
          (header) => wData.header === header
        )
        if (whitelistItem) {
          return wData
        }
        return undefined
      })
      .filter((wData) => wData) as Array<TGlobalTableDataType>

    const headers = whitelistData.map((wData) => wData.header)

    const processedTableData: TReportTableDataV3 = {
      columnIdList: headers,
      columnNameList: whitelistData.map((wData) => wData.translatedMetricName),
      columnNameTooltipList: whitelistData.map(
        (wData) => wData.translatedTooltipMetricName
      ),
      columnSpanList: headers.map((header) =>
        header === EReportColumnHeader.Name ? 2 : 1
      ),
      rowList: [],
    }

    tableDataRes.rows.forEach((row) => {
      const dataRow: Array<ReactNode | ReactNode[]> = []
      headers.forEach((header) => {
        let dataCell: ReactNode | ReactNode[]
        const dataValue = row[header as TDAPositionsTableHeaders]

        switch (header) {
          case EReportColumnHeader.Name:
            dataCell = <TableDataCellName dataValue={dataValue?.toString()} />
            break
          case EReportColumnHeader.Adgroups:
            dataCell = (
              <TableDataCellAdGroups
                dataValue={dataValue as unknown as Array<string>}
              />
            )
            break
          case EReportColumnHeader.Status:
            dataCell = (
              <TableDataCellStatus
                dataValue={dataValue?.toString() as EStatus}
                key={`${row.id}-${sortBy}-${sortOrder}`}
              />
            )
            break
          case EReportColumnHeader.BidPrice:
            dataCell = (
              <TableDataCellBidPrice
                dataValue={Number(dataValue)}
                dataId={row.id}
                orgType={orgType}
                status={row.status as EStatus}
                floorPrice={getPositionFloorPrice(row.id) ?? undefined}
                setErrorUpdate={setErrorUpdate}
                setShowUpdateErrorToast={setShowUpdateErrorToast}
              />
            )
            break
          case EReportColumnHeader.Impressions:
            dataCell = (
              <TableDataCellImpressions dataValue={Number(dataValue)} />
            )
            break
          case EReportColumnHeader.Clicks:
            dataCell = (
              <TableDataCellImpressions dataValue={Number(dataValue)} />
            )
            break
          case EReportColumnHeader.Ctr:
            dataCell = <TableDataCellCtr dataValue={Number(dataValue)} />
            break
          case EReportColumnHeader.UniqueReach:
            dataCell = (
              <TableDataCellUniqueReach dataValue={Number(dataValue)} />
            )
            break
          case EReportColumnHeader.Frequency:
            dataCell = <TableDataCellFrequency dataValue={Number(dataValue)} />
            break
          case EReportColumnHeader.Costs:
            dataCell = <TableDataCellCosts dataValue={Number(dataValue)} />
            break
          case EReportColumnHeader.Cpm:
            dataCell = <TableDataCellCpm dataValue={Number(dataValue)} />
            break
          case EReportColumnHeader.ShareOfVoice:
            dataCell = (
              <TableDataCellShareOfVoice dataValue={Number(dataValue)} />
            )
            break
          case EReportColumnHeader.WinRate:
            dataCell = <TableDataCellWinRate dataValue={Number(dataValue)} />
            break
          case EReportColumnHeader.Carts:
            dataCell = <TableDataCellCarts dataValue={Number(dataValue)} />
            break
          case EReportColumnHeader.PvCarts:
            dataCell = <TableDataCellCarts dataValue={Number(dataValue)} />
            break
          case EReportColumnHeader.Purchases:
            dataCell = <TableDataCellPurchases dataValue={Number(dataValue)} />
            break
          case EReportColumnHeader.PvPurchases:
            dataCell = <TableDataCellPurchases dataValue={Number(dataValue)} />
            break
          case EReportColumnHeader.Revenue:
            dataCell = <TableDataCellRevenue dataValue={Number(dataValue)} />
            break
          case EReportColumnHeader.PvRevenue:
            dataCell = <TableDataCellRevenue dataValue={Number(dataValue)} />
            break
          case EReportColumnHeader.Roas:
            dataCell = <TableDataCellRoas dataValue={Number(dataValue)} />
            break

          default:
            dataCell = undefined
            break
        }

        if (dataCell) {
          dataRow.push(dataCell)
        }
      })

      processedTableData.rowList.push(dataRow)
    })

    return processedTableData
  }

  useEffect(() => {
    if (startDate && endDate && sortBy && sortOrder) {
      refetch()
    }
  }, [startDate, endDate, sortBy, sortOrder])

  useEffect(() => {
    if (refreshTable) {
      refetch()
      dispatch(setRefreshTable({ refreshTable: false }))
    }
  }, [refreshTable])

  useEffect(() => {
    if (Object.keys(tableDataResponse).length > 0) {
      setTableData(processTableDataResponse(tableDataResponse))
      onTableDataChange(tableDataResponse)
    }
  }, [tableDataResponse])

  return (
    <>
      {tableData?.rowList?.length &&
        tableData?.rowList?.length > 0 &&
        !isFetching && (
          <>
            {tableData?.rowList && (
              <ReportTableV3
                {...tableData}
                sortBy={sortBy}
                sortOrder={sortOrder}
                setSortBy={onSortByChange}
                setSortOrder={onSortOrderChange}
              />
            )}
          </>
        )}
      {tableData?.rowList?.length === 0 && (
        <StyledImage
          fluid
          src={getPlaceholderImage(EEntityType.AdGroupPosition, orgType)}
          alt="home_placeholder"
          cursor="default"
        />
      )}

      <ErrorToast
        show={showUpdateErrorToast}
        onClose={() => {
          setShowUpdateErrorToast(false)
          setErrorUpdate(undefined)
        }}
        translationKey={errorUpdate}
      />

      {isFetching && <TablePreloader />}
    </>
  )
}
