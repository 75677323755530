import React, { useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import dayjs from 'dayjs'

import { ReportPageTemplateLayout } from '../../components/ReportPageTemplateLayout'
import { ReportingCategoriesChartWrapper } from './ReportingCategoriesChartWrapper'
import { TReportExportType, TReportingFilters } from '../../../../models'
import { AppContext, CampaignContext } from '../../../../models/contexts'
import {
  selectReportingFilters,
  setIsReportingV3,
} from '../../../../store/reportingFilters'
import {
  NOT_AVAILABLE,
  exportFileDateFormat,
} from '../../../../utils/constants'
import { exportTableDataToFile } from '../../helpers'
import { useGetDatasetKeyTableWhitelist } from '../../hooks/use-get-dataset-key-table-whitelist'
import { ReportingCategoriesTableWrapper } from './ReportingCategoriesTableWrapper'
import {
  EChannelTypeGlobal,
  IDAReportCategoriesTable,
  ISPReportCategoriesTable,
} from '../../../../models/core'
import {
  convertChannelTypeToParam,
  convertEParamChannelTypeToEChannelTypeGlobal,
} from '../../../../utils/helpers'
import { getCategoriesEDatasetKeyFromChannelTypeGlobal } from '../helpers/get-categories-e-dataset-key-from-channel-type-global'
import { TDatasetWhitelistKey } from '../../models'
import { sanitizeDataTestId } from '../../../../utils'

export const ReportingCategoriesTemplate = ({
  channelType: propChannelType,
}: {
  channelType?: EChannelTypeGlobal
}) => {
  const pageTitle = propChannelType
    ? `reporting.header.categories.${sanitizeDataTestId(propChannelType)}`
    : 'reporting.header.categories'

  const intl = useIntl()
  const dispatch = useDispatch()

  const [appContext] = useContext(AppContext)
  const campaign = useContext(CampaignContext)
  const { channelType: reportingFilterChannelType } = useSelector(
    selectReportingFilters
  )
  const channelType =
    propChannelType ||
    convertEParamChannelTypeToEChannelTypeGlobal(
      reportingFilterChannelType ||
        convertChannelTypeToParam(campaign.channelType)
    )

  const [tableData, setTableData] = useState<
    ISPReportCategoriesTable | IDAReportCategoriesTable
  >()

  const onTableDataChange = (
    categoriesTableData: ISPReportCategoriesTable | IDAReportCategoriesTable
  ) => {
    setTableData(categoriesTableData)
  }
  const whitelistData = useGetDatasetKeyTableWhitelist({
    key:
      (tableData?.datasetKey as TDatasetWhitelistKey) ||
      getCategoriesEDatasetKeyFromChannelTypeGlobal(channelType),
  })

  const onExportData = (
    { startDate: start, endDate: end }: TReportingFilters,
    type: TReportExportType
  ) => {
    const orgName =
      appContext.userOrg?.name ?? appContext.userOrg?.type ?? NOT_AVAILABLE
    const translatedPageTitle = intl
      .formatMessage({ id: pageTitle })
      .replace(/[-]+/g, '')
      .replace(/[ ]+/g, '_')
    const sDate = dayjs(start).format(exportFileDateFormat)
    const eDate = dayjs(end).format(exportFileDateFormat)

    const fileName = `${orgName}_${translatedPageTitle}_${sDate}_${eDate}`
    if (tableData) {
      exportTableDataToFile({
        tableDataRows: tableData.rows,
        tableHeaders: whitelistData,
        fileName,
        fileType: type,
      })
    }
  }

  useEffect(() => {
    dispatch(setIsReportingV3({ IsReportingV3: true }))
  }, [])

  return (
    <>
      <ReportPageTemplateLayout
        title={pageTitle}
        isExportDisabled={Boolean(!tableData?.rows.length)}
        chartWrapper={
          <ReportingCategoriesChartWrapper channelType={channelType!} />
        }
        tableWrapper={
          <ReportingCategoriesTableWrapper
            onTableDataChange={onTableDataChange}
            channelType={channelType!}
          />
        }
        onExportData={onExportData}
      />
    </>
  )
}
