import React, { useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import dayjs from 'dayjs'

import { ReportPageTemplateLayout } from '../../components/ReportPageTemplateLayout'
import { ReportingProductsChartWrapper } from './ReportingProductsChartWrapper'

import {
  ISPReportProductsTable,
  TReportExportType,
  TReportingFilters,
} from '../../../../models'
import {
  AdGroupContext,
  AppContext,
  CampaignContext,
} from '../../../../models/contexts'

import {
  selectReportingFilters,
  setAdGroupIds,
  setCampaignIds,
  setIsReportingV3,
} from '../../../../store/reportingFilters'

import {
  NOT_AVAILABLE,
  exportFileDateFormat,
} from '../../../../utils/constants'
import { exportTableDataToFile } from '../../helpers'
import { useGetDatasetKeyTableWhitelist } from '../../hooks/use-get-dataset-key-table-whitelist'
import { ReportingProductsTableWrapper } from './ReportingProductsTableWrapper'
import {
  EParamChannelTypeGlobal,
  IDAReportProductsTable,
} from '../../../../models/core'
import {
  convertChannelTypeToParam,
  convertEParamChannelTypeToEParamChannelTypeGlobal,
} from '../../../../utils/helpers'
import { sanitizeDataTestId } from '../../../../utils'
import { TDatasetWhitelistKey } from '../../models'
import { getProductEDatasetKeyFromChannelTypeGlobal } from '../helpers/get-product-e-dataset-key-from-channel-type-global'

/**
 * One report template per entity
 * Ex: ReportCategoriesTemplate, ReportCampaignsTemplate, etc ...
 */
export const ReportingProductsTemplate = ({
  channelType: propChannelType,
}: {
  channelType?: EParamChannelTypeGlobal
}) => {
  const pageTitle = propChannelType
    ? `reporting.header.products.${sanitizeDataTestId(propChannelType)}`
    : 'reporting.header.products'

  const dispatch = useDispatch()
  const intl = useIntl()

  const [appContext] = useContext(AppContext)
  const campaign = useContext(CampaignContext)
  const adGroup = useContext(AdGroupContext)
  const { channelType: reportingFilterChannelType } = useSelector(
    selectReportingFilters
  )

  const channelType =
    propChannelType ||
    convertEParamChannelTypeToEParamChannelTypeGlobal(
      reportingFilterChannelType ||
        convertChannelTypeToParam(campaign.channelType)
    )

  const [tableData, setTableData] = useState<
    ISPReportProductsTable | IDAReportProductsTable
  >()

  const [reportingFiltersIsSetup, setReportingFiltersIsSetup] = useState(false)

  const onTableDataChange = (
    productsTableData: ISPReportProductsTable | IDAReportProductsTable
  ) => {
    setTableData(productsTableData)
  }

  const whitelistData = useGetDatasetKeyTableWhitelist({
    key:
      (tableData?.datasetKey as TDatasetWhitelistKey) ||
      getProductEDatasetKeyFromChannelTypeGlobal(channelType),
  })

  const onExportData = (
    { startDate: start, endDate: end }: TReportingFilters,
    type: TReportExportType
  ) => {
    const orgName =
      appContext.userOrg?.name ?? appContext.userOrg?.type ?? NOT_AVAILABLE
    const translatedPageTitle = intl
      .formatMessage({ id: pageTitle })
      .replace(/[-]+/g, '')
      .replace(/[ ]+/g, '_')
    const sDate = dayjs(start).format(exportFileDateFormat)
    const eDate = dayjs(end).format(exportFileDateFormat)

    const fileName = `${orgName}_${translatedPageTitle}_${sDate}_${eDate}`
    if (tableData) {
      exportTableDataToFile({
        tableDataRows: tableData.rows,
        tableHeaders: whitelistData,
        fileName,
        fileType: type,
      })
    }
  }

  useEffect(() => {
    dispatch(
      setCampaignIds({
        campaignIds: campaign?.id,
      })
    )
    dispatch(
      setAdGroupIds({
        adGroupIds: adGroup?.id,
      })
    )
    setReportingFiltersIsSetup(true)
  }, [campaign.id, adGroup.id])

  useEffect(() => {
    dispatch(setIsReportingV3({ IsReportingV3: true }))
  }, [])

  return (
    <>
      {reportingFiltersIsSetup &&
        (reportingFilterChannelType || channelType) && (
          <ReportPageTemplateLayout
            title={pageTitle}
            isExportDisabled={Boolean(!tableData?.rows.length)}
            chartWrapper={
              <ReportingProductsChartWrapper channelType={channelType} />
            }
            tableWrapper={
              <ReportingProductsTableWrapper
                onTableDataChange={onTableDataChange}
                channelType={channelType}
              />
            }
            onExportData={onExportData}
          />
        )}
    </>
  )
}
