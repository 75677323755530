import { EReportColumnHeader } from '@unfoldrtech/portal-mic/dist/src/openapi/core'
import { TGlobalMetrics } from '../../../models'

export const daMetricNames: Array<TGlobalMetrics> = [
  EReportColumnHeader.Impressions,
  EReportColumnHeader.UniqueReach,
  EReportColumnHeader.Frequency,
  EReportColumnHeader.Clicks,
  EReportColumnHeader.Ctr,
  EReportColumnHeader.Costs,
  EReportColumnHeader.Cpm,
  EReportColumnHeader.ShareOfVoice,
  EReportColumnHeader.WinRate,
  // EReportColumnHeader.AvgRank, // not available yet
  EReportColumnHeader.Carts,
  EReportColumnHeader.Purchases,
  EReportColumnHeader.Revenue,
  EReportColumnHeader.Roas,
  EReportColumnHeader.PvCarts,
  EReportColumnHeader.PvPurchases,
  EReportColumnHeader.PvRevenue,
]
