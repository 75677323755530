import { EOrgType } from '@unfoldrtech/portal-mic'
import useGetAdvertiserReportingCreativesChartV3 from '../../../../hooks/use-get-advertiser-reporting-creatives-chart-v3'
import useGetAdvertiserReportingCreativesTableV3 from '../../../../hooks/use-get-advertiser-reporting-creatives-table-v3'
import useGetRetailerReportingCreativesChartV3 from '../../../../hooks/use-get-retailer-reporting-creatives-chart-v3'
import useGetRetailerReportingCreativesTableV3 from '../../../../hooks/use-get-retailer-reporting-creatives-table-v3'

export const getTableAndChartCreativesFn = ({
  orgType = EOrgType.Advertiser,
}: {
  orgType?: EOrgType
}) => {
  let tableFn
  let chartFn
  if (orgType === EOrgType.Advertiser) {
    tableFn = useGetAdvertiserReportingCreativesTableV3
    chartFn = useGetAdvertiserReportingCreativesChartV3
  }
  if (orgType === EOrgType.Retailer) {
    tableFn = useGetRetailerReportingCreativesTableV3
    chartFn = useGetRetailerReportingCreativesChartV3
  }

  return { tableFn, chartFn }
}
