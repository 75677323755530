import React, { lazy } from 'react'
import { Route } from 'react-router-dom'
import {
  EAdvertiserReportPageType,
  EEntityType,
  EParamChannelType,
  EParamInventoryType,
  EUserRole,
} from '@unfoldrtech/portal-mic'
import { PortalRoutes } from './PortalRoutes'
import AuthenticatedRoute from './AuthenticatedRoute'
import ReportPageWrapper from '../ReportPageWrapper'
import { lazyRetry } from '../../utils/lazyRetry'
import { useFeatureFlags } from '../../hooks/useFeatureFlags'
import {
  PORTAL_FF_REPORTING_SEARCH_TERMS,
  PORTAL_FF_DA,
  PORTAL_FF_DOOH_REPORTING_VIEW,
  PORTAL_FF_SP,
  PORTAL_FF_REPORTING_V_3_PRODUCTS,
  PORTAL_FF_DA_PURCHASE_ATTRIBUTION,
  PORTAL_FF_REPORTING_V3_POSITIONS,
  PORTAL_FF_REPORTING_V_3_CAMPAIGNS,
  PORTAL_FF_REPORTING_V_3_KEYWORDS,
  PORTAL_FF_REPORTING_V_3_AUDIENCES,
  PORTAL_FF_REPORTING_V_3_CATEGORIES,
  PORTAL_FF_REPORTING_V_3_AD_GROUPS,
  PORTAL_FF_REPORTING_V_3_CREATIVES,
} from '../../utils/constants'
import { ReportingDOOHLocationTemplate } from '../ReportPageWrapperV3/dooh-location/components/ReportingDOOHLocationTemplate'
import { ReportingAudienceTemplate } from '../ReportPageWrapperV3/audience/components/ReportingAudienceTemplate'
import { ReportingProductsTemplate } from '../ReportPageWrapperV3/products/components/ReportingProductsTemplate'
import { ReportingDAPositionsTemplate } from '../ReportPageWrapperV3/da-positions/components/ReportingDAPositionsTemplate'
import {
  EChannelTypeGlobal,
  EInventoryTypeGlobal,
  EParamChannelTypeGlobal,
} from '../../models/core'
import { ReportingCampaignsTemplate } from '../ReportPageWrapperV3/campaigns/components/ReportingCampaignsTemplate'
import { ReportingKeywordsTemplate } from '../ReportPageWrapperV3/keywords/components/ReportingKeywordsTemplate'
import { ReportingCategoriesTemplate } from '../ReportPageWrapperV3/categories/components/ReportingCategoriesTemplates'
import { ReportingAdGroupsTemplate } from '../ReportPageWrapperV3/ad-groups/components/ReportingAdGroupsTemplate'
import { ReportingCreativesTemplate } from '../ReportPageWrapperV3/creatives/components/ReportingCreativesTemplate'

const Advertiser = lazy(() => lazyRetry(() => import('../../pages/Advertiser')))
const AdvertiserOverview = lazy(() =>
  lazyRetry(() => import('../../pages/Advertiser/Overview'))
)

const Analytics = lazy(() =>
  lazyRetry(() => import('../../pages/Advertiser/Routes/Analytics'))
)
const Overview = lazy(() =>
  lazyRetry(() => import('../../pages/Advertiser/Routes/Analytics/Overview'))
)
const Campaigns = lazy(() =>
  lazyRetry(() => import('../../pages/Advertiser/Routes/Analytics/Campaigns'))
)
const CampaignSettings = lazy(() =>
  lazyRetry(
    () => import('../../pages/Advertiser/Routes/Analytics/CampaignSettings')
  )
)
const AdGroups = lazy(() =>
  lazyRetry(() => import('../../pages/Advertiser/Routes/Analytics/AdGroups'))
)
const AdGroupSettings = lazy(() =>
  lazyRetry(
    () => import('../../pages/Advertiser/Routes/Analytics/AdGroupSettings')
  )
)

const NotFound = lazy(() => lazyRetry(() => import('../../pages/NotFound')))

const AdvertiserAnalyticsRoutes = () => {
  const featureFlags = useFeatureFlags()

  const isPortalSPFlagEnabled = featureFlags[PORTAL_FF_SP]
  const isPortalDAFlagEnabled = featureFlags[PORTAL_FF_DA]
  const isPortalSPOrDAFlagEnabled =
    isPortalSPFlagEnabled || isPortalDAFlagEnabled
  const isPortalDOOHReportingViewFlagEnabled =
    featureFlags[PORTAL_FF_DOOH_REPORTING_VIEW]
  const isPortalReportingV3ProductsFlagEnabled =
    featureFlags[PORTAL_FF_REPORTING_V_3_PRODUCTS]
  const isPortalReportingV3PositionsFlagEnabled =
    featureFlags[PORTAL_FF_REPORTING_V3_POSITIONS]
  const isPortalDAPurchaseAttributionEnabled =
    featureFlags[PORTAL_FF_DA_PURCHASE_ATTRIBUTION]
  const isPortalReportingV3SPOrDAEnabled =
    (isPortalSPFlagEnabled && isPortalReportingV3ProductsFlagEnabled) ||
    (isPortalDAFlagEnabled &&
      isPortalDAPurchaseAttributionEnabled &&
      isPortalReportingV3ProductsFlagEnabled)
  const isPortalReportingV3CampaignsEnabled =
    featureFlags[PORTAL_FF_REPORTING_V_3_CAMPAIGNS]
  const isPortalReportingV3KeywordsEnabled =
    featureFlags[PORTAL_FF_REPORTING_V_3_KEYWORDS]
  const isPortalReportingV3AudiencesFlagEnabled =
    featureFlags[PORTAL_FF_REPORTING_V_3_AUDIENCES]
  const isAudiencesEnabled =
    (isPortalDAFlagEnabled || isPortalDOOHReportingViewFlagEnabled) &&
    isPortalReportingV3AudiencesFlagEnabled
  const isPortalReportingV3CategoriesEnabled =
    featureFlags[PORTAL_FF_REPORTING_V_3_CATEGORIES]
  const isPortalReportingV3AdGroupsEnabled =
    featureFlags[PORTAL_FF_REPORTING_V_3_AD_GROUPS]
  const isPortalReportingV3CreativesEnabled =
    featureFlags[PORTAL_FF_REPORTING_V_3_CREATIVES]

  return (
    <PortalRoutes>
      <Route
        path="advertiser/:orgId"
        element={
          <AuthenticatedRoute authRole={EUserRole.AdvertiserInsights}>
            <Advertiser />
          </AuthenticatedRoute>
        }
      >
        <Route
          path="retailer/:retailerId"
          element={
            <AuthenticatedRoute authRole={EUserRole.AdvertiserInsights}>
              <AdvertiserOverview />
            </AuthenticatedRoute>
          }
        >
          <Route
            path="analytics"
            element={
              <AuthenticatedRoute authRole={EUserRole.AdvertiserInsights}>
                <Analytics />
              </AuthenticatedRoute>
            }
          >
            {/* Analytics page showing campaigns & ad groups */}

            <Route
              path=""
              element={
                <AuthenticatedRoute authRole={EUserRole.AdvertiserInsights}>
                  <Overview />
                </AuthenticatedRoute>
              }
            >
              <Route
                path="campaigns-all"
                element={
                  <AuthenticatedRoute authRole={EUserRole.AdvertiserInsights}>
                    {isPortalReportingV3CampaignsEnabled ? (
                      <>
                        <ReportingCampaignsTemplate />
                      </>
                    ) : (
                      <ReportPageWrapper
                        title="advertiser.nav.campaigns"
                        entityType={EEntityType.Campaign}
                      />
                    )}
                  </AuthenticatedRoute>
                }
                /* exact */
              />
              {isPortalSPFlagEnabled && (
                <Route
                  path="campaigns-sp"
                  element={
                    <AuthenticatedRoute authRole={EUserRole.AdvertiserInsights}>
                      {isPortalReportingV3CampaignsEnabled ? (
                        <>
                          <ReportingCampaignsTemplate
                            channelType={EChannelTypeGlobal.SponsoredProduct}
                          />
                        </>
                      ) : (
                        <ReportPageWrapper
                          title="reporting.header.campaigns.sponsoredProducts"
                          channelType={EParamChannelType.SponsoredProduct}
                          entityType={EEntityType.Campaign}
                        />
                      )}
                    </AuthenticatedRoute>
                  }
                  /* exact */
                />
              )}
              {isPortalDAFlagEnabled && (
                <Route
                  path="campaigns-da"
                  element={
                    <AuthenticatedRoute authRole={EUserRole.AdvertiserInsights}>
                      {isPortalReportingV3CampaignsEnabled ? (
                        <>
                          <ReportingCampaignsTemplate
                            channelType={EChannelTypeGlobal.DisplayAd}
                          />
                        </>
                      ) : (
                        <ReportPageWrapper
                          title="reporting.header.campaigns.displayAds"
                          channelType={EParamChannelType.Da}
                          entityType={EEntityType.Campaign}
                        />
                      )}
                    </AuthenticatedRoute>
                  }
                  /* exact */
                />
              )}
              {isPortalDOOHReportingViewFlagEnabled && (
                <Route
                  path="campaigns-dooh"
                  element={
                    <AuthenticatedRoute authRole={EUserRole.AdvertiserInsights}>
                      {isPortalReportingV3CampaignsEnabled ? (
                        <>
                          <ReportingCampaignsTemplate
                            channelType={EChannelTypeGlobal.DigitalOutOfHome}
                          />
                        </>
                      ) : (
                        <ReportPageWrapper
                          title="reporting.header.campaigns.dooh"
                          channelType={EParamChannelType.Dooh}
                          entityType={EEntityType.Campaign}
                        />
                      )}
                    </AuthenticatedRoute>
                  }
                  /* exact */
                />
              )}

              <Route
                path="adgroups-all"
                element={
                  <AuthenticatedRoute authRole={EUserRole.AdvertiserInsights}>
                    {isPortalReportingV3AdGroupsEnabled ? (
                      <ReportingAdGroupsTemplate />
                    ) : (
                      <ReportPageWrapper
                        title="reporting.header.adGroups"
                        advertiserReportPageType={
                          EAdvertiserReportPageType.Adgroups
                        }
                        entityType={EEntityType.AdGroup}
                      />
                    )}
                  </AuthenticatedRoute>
                }
                /* exact */
              />
              {isPortalSPOrDAFlagEnabled && (
                <Route
                  path="adgroups-categories"
                  element={
                    <AuthenticatedRoute authRole={EUserRole.AdvertiserInsights}>
                      {isPortalReportingV3AdGroupsEnabled ? (
                        <ReportingAdGroupsTemplate
                          inventoryType={EInventoryTypeGlobal.Category}
                        />
                      ) : (
                        <ReportPageWrapper
                          title="advertiser.reporting.header.inventory.categories"
                          inventoryType={EParamInventoryType.Category}
                          advertiserReportPageType={
                            EAdvertiserReportPageType.Adgroups
                          }
                          entityType={EEntityType.AdGroupCategory}
                        />
                      )}
                    </AuthenticatedRoute>
                  }
                  /* exact */
                />
              )}
              {isPortalSPOrDAFlagEnabled && (
                <Route
                  path="adgroups-search"
                  element={
                    <AuthenticatedRoute authRole={EUserRole.AdvertiserInsights}>
                      {isPortalReportingV3AdGroupsEnabled ? (
                        <ReportingAdGroupsTemplate
                          inventoryType={EInventoryTypeGlobal.Keyword}
                        />
                      ) : (
                        <ReportPageWrapper
                          title="advertiser.reporting.header.inventory.search"
                          inventoryType={EParamInventoryType.Keyword}
                          advertiserReportPageType={
                            EAdvertiserReportPageType.Adgroups
                          }
                          entityType={EEntityType.AdGroupKeyword}
                        />
                      )}
                    </AuthenticatedRoute>
                  }
                  /* exact */
                />
              )}
              {isPortalDAFlagEnabled && (
                <Route
                  path="adgroups-pagePosition"
                  element={
                    <AuthenticatedRoute authRole={EUserRole.AdvertiserInsights}>
                      {isPortalReportingV3AdGroupsEnabled ? (
                        <ReportingAdGroupsTemplate
                          inventoryType={EInventoryTypeGlobal.PagePosition}
                        />
                      ) : (
                        <ReportPageWrapper
                          title="advertiser.reporting.header.inventory.pagePosition"
                          inventoryType={EParamInventoryType.PagePosition}
                          advertiserReportPageType={
                            EAdvertiserReportPageType.Adgroups
                          }
                          entityType={EEntityType.AdGroupPosition}
                          channelType={EParamChannelType.Da}
                        />
                      )}
                    </AuthenticatedRoute>
                  }
                  /* exact */
                />
              )}
              {isPortalDOOHReportingViewFlagEnabled && (
                <Route
                  path="adgroups-screen"
                  element={
                    <AuthenticatedRoute authRole={EUserRole.AdvertiserInsights}>
                      {isPortalReportingV3AdGroupsEnabled ? (
                        <ReportingAdGroupsTemplate
                          inventoryType={EInventoryTypeGlobal.Screen}
                        />
                      ) : (
                        <ReportPageWrapper
                          title="advertiser.reporting.header.inventory.screen"
                          inventoryType={'screen' as EParamInventoryType} // currently unsupported, wait for actual endpoint implementation. PORT-867
                          advertiserReportPageType={
                            EAdvertiserReportPageType.Adgroups
                          }
                          entityType={undefined} // currently unsupported, wait for actual endpoint implementation. PORT-867
                          channelType={EParamChannelType.Dooh}
                        />
                      )}
                    </AuthenticatedRoute>
                  }
                  /* exact */
                />
              )}
              {isPortalSPFlagEnabled &&
                isPortalReportingV3ProductsFlagEnabled && (
                  <Route
                    path="products-sp"
                    element={
                      <AuthenticatedRoute
                        authRole={EUserRole.AdvertiserInsights}
                      >
                        <ReportingProductsTemplate
                          channelType={EParamChannelTypeGlobal.SponsoredProduct}
                        />
                      </AuthenticatedRoute>
                    }
                  />
                )}
              {isPortalSPFlagEnabled && isPortalReportingV3KeywordsEnabled && (
                <Route
                  path="keywords-sp"
                  element={
                    <AuthenticatedRoute authRole={EUserRole.AdvertiserInsights}>
                      <ReportingKeywordsTemplate
                        channelType={EChannelTypeGlobal.SponsoredProduct}
                      />
                    </AuthenticatedRoute>
                  }
                />
              )}

              {isPortalDAFlagEnabled && isPortalReportingV3KeywordsEnabled && (
                <Route
                  path="keywords-da"
                  element={
                    <AuthenticatedRoute authRole={EUserRole.AdvertiserInsights}>
                      <ReportingKeywordsTemplate
                        channelType={EChannelTypeGlobal.DisplayAd}
                      />
                    </AuthenticatedRoute>
                  }
                />
              )}

              {isPortalSPFlagEnabled &&
                isPortalReportingV3CategoriesEnabled && (
                  <Route
                    path="categories-sp"
                    element={
                      <AuthenticatedRoute
                        authRole={EUserRole.AdvertiserInsights}
                      >
                        <ReportingCategoriesTemplate
                          channelType={EChannelTypeGlobal.SponsoredProduct}
                        />
                      </AuthenticatedRoute>
                    }
                  />
                )}

              {isPortalDAFlagEnabled &&
                isPortalReportingV3CategoriesEnabled && (
                  <Route
                    path="categories-da"
                    element={
                      <AuthenticatedRoute
                        authRole={EUserRole.AdvertiserInsights}
                      >
                        <ReportingCategoriesTemplate
                          channelType={EChannelTypeGlobal.DisplayAd}
                        />
                      </AuthenticatedRoute>
                    }
                  />
                )}

              {isPortalSPFlagEnabled &&
                !isPortalReportingV3ProductsFlagEnabled && (
                  <Route
                    path="products-sp"
                    element={
                      <AuthenticatedRoute
                        authRole={EUserRole.AdvertiserInsights}
                      >
                        <ReportPageWrapper
                          title="products"
                          advertiserReportPageType={
                            EAdvertiserReportPageType.Products
                          }
                          navigateDisabled={true}
                          entityType={EEntityType.AdGroupProduct}
                        />
                      </AuthenticatedRoute>
                    }
                    /* exact */
                  />
                )}

              {isPortalDAFlagEnabled &&
                isPortalDAPurchaseAttributionEnabled &&
                isPortalReportingV3ProductsFlagEnabled && (
                  <Route
                    path="products-da"
                    element={
                      <AuthenticatedRoute
                        authRole={EUserRole.AdvertiserInsights}
                      >
                        <ReportingProductsTemplate
                          channelType={EParamChannelTypeGlobal.DisplayAd}
                        />
                      </AuthenticatedRoute>
                    }
                  />
                )}
            </Route>

            {/* Analytics page for a campaign showing ad groups */}
            <Route
              path="campaigns/:campaignId"
              element={
                <AuthenticatedRoute authRole={EUserRole.AdvertiserInsights}>
                  <Campaigns />
                </AuthenticatedRoute>
              }
            >
              <Route
                path="adgroups"
                element={
                  <AuthenticatedRoute authRole={EUserRole.AdvertiserInsights}>
                    {isPortalReportingV3AdGroupsEnabled ? (
                      <ReportingAdGroupsTemplate />
                    ) : (
                      <ReportPageWrapper
                        title="reporting.header.adGroups"
                        advertiserReportPageType={
                          EAdvertiserReportPageType.Adgroups
                        }
                        entityType={EEntityType.AdGroup}
                      />
                    )}
                  </AuthenticatedRoute>
                }
                /* exact */
              />
              {isPortalDAFlagEnabled && (
                <Route
                  path="creatives-da"
                  element={
                    <AuthenticatedRoute authRole={EUserRole.AdvertiserInsights}>
                      {isPortalReportingV3CreativesEnabled ? (
                        <ReportingCreativesTemplate />
                      ) : (
                        <ReportPageWrapper
                          title="reporting.header.creatives"
                          advertiserReportPageType={
                            EAdvertiserReportPageType.Creatives
                          }
                          entityType={EEntityType.AdGroupCreative}
                        />
                      )}
                    </AuthenticatedRoute>
                  }
                />
              )}
              {isPortalDOOHReportingViewFlagEnabled && (
                <Route
                  path="creatives-dooh"
                  element={
                    <AuthenticatedRoute authRole={EUserRole.AdvertiserInsights}>
                      {isPortalReportingV3CreativesEnabled ? (
                        <ReportingCreativesTemplate />
                      ) : (
                        <ReportPageWrapper
                          title="reporting.header.creatives"
                          advertiserReportPageType={
                            EAdvertiserReportPageType.Creatives
                          }
                          entityType={'adGroupDoohCreative' as EEntityType} // Should be updated after creatives endpoint implementation. PORT-867
                        />
                      )}
                    </AuthenticatedRoute>
                  }
                />
              )}
              {isPortalReportingV3SPOrDAEnabled && (
                <Route
                  path="products"
                  element={
                    <AuthenticatedRoute authRole={EUserRole.AdvertiserInsights}>
                      <ReportingProductsTemplate />
                    </AuthenticatedRoute>
                  }
                />
              )}
              {isPortalDAFlagEnabled && (
                <Route
                  path="positions"
                  element={
                    <AuthenticatedRoute authRole={EUserRole.AdvertiserInsights}>
                      {isPortalReportingV3PositionsFlagEnabled ? (
                        <ReportingDAPositionsTemplate />
                      ) : (
                        <ReportPageWrapper
                          title="reporting.header.positions"
                          advertiserReportPageType={
                            EAdvertiserReportPageType.Positions
                          }
                          entityType={EEntityType.AdGroupPosition}
                        />
                      )}
                    </AuthenticatedRoute>
                  }
                />
              )}
              {isAudiencesEnabled && (
                <Route
                  path="audiences"
                  element={
                    <AuthenticatedRoute authRole={EUserRole.AdvertiserInsights}>
                      <ReportingAudienceTemplate />
                    </AuthenticatedRoute>
                  }
                />
              )}
              <Route
                path="settings"
                element={
                  <AuthenticatedRoute authRole={EUserRole.AdvertiserCampaigns}>
                    <CampaignSettings />
                  </AuthenticatedRoute>
                }
                /* exact */
              />
            </Route>

            {/* Analytics page for a campaign showing products/keywords/categories for an adgroup */}
            <Route
              path="campaigns/:campaignId/adgroups/:adgroupId"
              element={
                <AuthenticatedRoute authRole={EUserRole.AdvertiserInsights}>
                  <AdGroups />
                </AuthenticatedRoute>
              }
            >
              {isPortalDAFlagEnabled && (
                <Route
                  path="creatives-da"
                  element={
                    <AuthenticatedRoute authRole={EUserRole.AdvertiserInsights}>
                      {isPortalReportingV3CreativesEnabled ? (
                        <ReportingCreativesTemplate />
                      ) : (
                        <ReportPageWrapper
                          title="creatives"
                          advertiserReportPageType={
                            EAdvertiserReportPageType.Creatives
                          }
                          navigateDisabled={true}
                          entityType={EEntityType.AdGroupCreative}
                        />
                      )}
                    </AuthenticatedRoute>
                  }
                />
              )}
              {isPortalDOOHReportingViewFlagEnabled && (
                <Route
                  path="creatives-dooh"
                  element={
                    <AuthenticatedRoute authRole={EUserRole.AdvertiserInsights}>
                      {isPortalReportingV3CreativesEnabled ? (
                        <ReportingCreativesTemplate />
                      ) : (
                        <ReportPageWrapper
                          title="creatives"
                          advertiserReportPageType={
                            EAdvertiserReportPageType.Creatives
                          }
                          navigateDisabled={true}
                          entityType={'adGroupDoohCreative' as EEntityType}
                        />
                      )}
                    </AuthenticatedRoute>
                  }
                />
              )}
              {isPortalReportingV3SPOrDAEnabled && (
                <Route
                  path="products"
                  element={
                    <AuthenticatedRoute authRole={EUserRole.AdvertiserInsights}>
                      <ReportingProductsTemplate />
                    </AuthenticatedRoute>
                  }
                />
              )}

              {isPortalSPFlagEnabled &&
                !isPortalReportingV3ProductsFlagEnabled && (
                  <Route
                    path="products"
                    element={
                      <AuthenticatedRoute
                        authRole={EUserRole.AdvertiserInsights}
                      >
                        <ReportPageWrapper
                          title="products"
                          advertiserReportPageType={
                            EAdvertiserReportPageType.Products
                          }
                          navigateDisabled={true}
                          entityType={EEntityType.AdGroupProduct}
                        />
                      </AuthenticatedRoute>
                    }
                    /* exact */
                  />
                )}

              {isPortalSPOrDAFlagEnabled && (
                <Route
                  path="categories"
                  element={
                    <AuthenticatedRoute authRole={EUserRole.AdvertiserInsights}>
                      {isPortalReportingV3CategoriesEnabled ? (
                        <ReportingCategoriesTemplate />
                      ) : (
                        <ReportPageWrapper
                          title="categories"
                          advertiserReportPageType={
                            EAdvertiserReportPageType.Categories
                          }
                          navigateDisabled={true}
                          entityType={EEntityType.AdGroupCategory}
                        />
                      )}
                    </AuthenticatedRoute>
                  }
                  /* exact */
                />
              )}

              {isPortalSPOrDAFlagEnabled && (
                <Route
                  path="keywords"
                  element={
                    <AuthenticatedRoute authRole={EUserRole.AdvertiserInsights}>
                      {isPortalReportingV3KeywordsEnabled ? (
                        <ReportingKeywordsTemplate />
                      ) : (
                        <ReportPageWrapper
                          title="keywords"
                          advertiserReportPageType={
                            EAdvertiserReportPageType.Keywords
                          }
                          navigateDisabled={true}
                          entityType={EEntityType.AdGroupKeyword}
                        />
                      )}
                    </AuthenticatedRoute>
                  }
                />
              )}
              {featureFlags[PORTAL_FF_REPORTING_SEARCH_TERMS] && (
                <Route
                  path="search-terms"
                  element={
                    <AuthenticatedRoute authRole={EUserRole.AdvertiserInsights}>
                      <ReportPageWrapper
                        title="search.terms"
                        subTitle="search.terms.description"
                        advertiserReportPageType={
                          EAdvertiserReportPageType.KeywordsInsights
                        }
                        navigateDisabled={true}
                        entityType={EEntityType.AdGroupSearchTerms}
                        virtual={true}
                      />
                    </AuthenticatedRoute>
                  }
                />
              )}

              {isPortalDAFlagEnabled && (
                <Route
                  path="pagePosition"
                  element={
                    <AuthenticatedRoute authRole={EUserRole.AdvertiserInsights}>
                      <ReportPageWrapper
                        title="pagePosition"
                        advertiserReportPageType={
                          EAdvertiserReportPageType.Pages
                        }
                        navigateDisabled={true}
                        entityType={EEntityType.AdGroupPosition}
                        channelType={EParamChannelType.Da}
                      />
                    </AuthenticatedRoute>
                  }
                />
              )}
              {isPortalDAFlagEnabled && (
                <Route
                  path="positions"
                  element={
                    <AuthenticatedRoute authRole={EUserRole.AdvertiserInsights}>
                      {isPortalReportingV3PositionsFlagEnabled ? (
                        <ReportingDAPositionsTemplate />
                      ) : (
                        <ReportPageWrapper
                          title="positions"
                          advertiserReportPageType={
                            EAdvertiserReportPageType.Positions
                          }
                          navigateDisabled={true}
                          entityType={EEntityType.AdGroupPosition}
                        />
                      )}
                    </AuthenticatedRoute>
                  }
                />
              )}
              {isPortalDOOHReportingViewFlagEnabled && (
                <Route
                  path="locations"
                  element={
                    <AuthenticatedRoute authRole={EUserRole.AdvertiserInsights}>
                      <ReportingDOOHLocationTemplate />
                    </AuthenticatedRoute>
                  }
                />
              )}
              {isAudiencesEnabled && (
                <Route
                  path="audiences"
                  element={
                    <AuthenticatedRoute authRole={EUserRole.AdvertiserInsights}>
                      <ReportingAudienceTemplate />
                    </AuthenticatedRoute>
                  }
                />
              )}
              <Route
                path="settings"
                element={
                  <AuthenticatedRoute authRole={EUserRole.AdvertiserCampaigns}>
                    <AdGroupSettings />
                  </AuthenticatedRoute>
                }
                /* exact */
              />
            </Route>

            {/* Analytics page showing products/keywords/categories for an adgroup  */}
            <Route
              path="adgroups/:adgroupId"
              element={
                <AuthenticatedRoute authRole={EUserRole.AdvertiserInsights}>
                  <AdGroups />
                </AuthenticatedRoute>
              }
            >
              {isPortalReportingV3SPOrDAEnabled && (
                <Route
                  path="products"
                  element={
                    <AuthenticatedRoute authRole={EUserRole.AdvertiserInsights}>
                      <ReportingProductsTemplate />
                    </AuthenticatedRoute>
                  }
                />
              )}

              {isPortalSPFlagEnabled &&
                !isPortalReportingV3ProductsFlagEnabled && (
                  <Route
                    path="products"
                    element={
                      <AuthenticatedRoute
                        authRole={EUserRole.AdvertiserInsights}
                      >
                        <ReportPageWrapper
                          title="products"
                          advertiserReportPageType={
                            EAdvertiserReportPageType.Products
                          }
                          navigateDisabled={true}
                          entityType={EEntityType.AdGroupProduct}
                        />
                      </AuthenticatedRoute>
                    }
                    /* exact */
                  />
                )}
              {featureFlags[PORTAL_FF_REPORTING_SEARCH_TERMS] && (
                <Route
                  path="search-terms"
                  element={
                    <AuthenticatedRoute authRole={EUserRole.AdvertiserInsights}>
                      <ReportPageWrapper
                        title="search.terms"
                        subTitle="search.terms.description"
                        advertiserReportPageType={
                          EAdvertiserReportPageType.KeywordsInsights
                        }
                        navigateDisabled={true}
                        entityType={EEntityType.AdGroupSearchTerms}
                        virtual={true}
                      />
                    </AuthenticatedRoute>
                  }
                />
              )}
              {isPortalSPOrDAFlagEnabled && (
                <Route
                  path="categories"
                  element={
                    <AuthenticatedRoute authRole={EUserRole.AdvertiserInsights}>
                      {isPortalReportingV3CategoriesEnabled ? (
                        <ReportingCategoriesTemplate />
                      ) : (
                        <ReportPageWrapper
                          title="categories"
                          advertiserReportPageType={
                            EAdvertiserReportPageType.Categories
                          }
                          navigateDisabled={true}
                          entityType={EEntityType.AdGroupCategory}
                        />
                      )}
                    </AuthenticatedRoute>
                  }
                  /* exact */
                />
              )}
              {isPortalSPOrDAFlagEnabled && (
                <Route
                  path="keywords"
                  element={
                    <AuthenticatedRoute authRole={EUserRole.AdvertiserInsights}>
                      {isPortalReportingV3KeywordsEnabled ? (
                        <ReportingKeywordsTemplate />
                      ) : (
                        <ReportPageWrapper
                          title="keywords"
                          advertiserReportPageType={
                            EAdvertiserReportPageType.Keywords
                          }
                          navigateDisabled={true}
                          entityType={EEntityType.AdGroupKeyword}
                        />
                      )}
                    </AuthenticatedRoute>
                  }
                />
              )}
              <Route
                path="creatives-da"
                element={
                  <AuthenticatedRoute authRole={EUserRole.AdvertiserInsights}>
                    {isPortalReportingV3CreativesEnabled ? (
                      <ReportingCreativesTemplate />
                    ) : (
                      <ReportPageWrapper
                        title="creatives"
                        advertiserReportPageType={
                          EAdvertiserReportPageType.Creatives
                        }
                        navigateDisabled={true}
                        entityType={EEntityType.AdGroupCreative}
                      />
                    )}
                  </AuthenticatedRoute>
                }
              />

              <Route
                path="creatives-dooh"
                element={
                  <AuthenticatedRoute authRole={EUserRole.AdvertiserInsights}>
                    {isPortalReportingV3CreativesEnabled ? (
                      <ReportingCreativesTemplate />
                    ) : (
                      <ReportPageWrapper
                        title="creatives"
                        advertiserReportPageType={
                          EAdvertiserReportPageType.Creatives
                        }
                        navigateDisabled={true}
                        entityType={EEntityType.AdGroupCreative}
                      />
                    )}
                  </AuthenticatedRoute>
                }
              />

              <Route
                path="positions"
                element={
                  <AuthenticatedRoute authRole={EUserRole.AdvertiserInsights}>
                    {isPortalReportingV3PositionsFlagEnabled ? (
                      <ReportingDAPositionsTemplate />
                    ) : (
                      <ReportPageWrapper
                        title="positions"
                        advertiserReportPageType={
                          EAdvertiserReportPageType.Positions
                        }
                        navigateDisabled={true}
                        entityType={EEntityType.AdGroupPosition}
                      />
                    )}
                  </AuthenticatedRoute>
                }
              />

              {isPortalDOOHReportingViewFlagEnabled && (
                <Route
                  path="locations"
                  element={
                    <AuthenticatedRoute authRole={EUserRole.AdvertiserInsights}>
                      <ReportingDOOHLocationTemplate />
                    </AuthenticatedRoute>
                  }
                />
              )}

              {isAudiencesEnabled && (
                <Route
                  path="audiences"
                  element={
                    <AuthenticatedRoute authRole={EUserRole.AdvertiserInsights}>
                      <ReportingAudienceTemplate />
                    </AuthenticatedRoute>
                  }
                />
              )}

              <Route
                path="settings"
                element={
                  <AuthenticatedRoute authRole={EUserRole.AdvertiserCampaigns}>
                    <AdGroupSettings />
                  </AuthenticatedRoute>
                }
                /* exact */
              />
            </Route>

            <Route path="*" element={<NotFound />} />
          </Route>
        </Route>
      </Route>
    </PortalRoutes>
  )
}

export default AdvertiserAnalyticsRoutes
