import {
  EEntityType,
  EOrgType,
  EStatus,
  EUserRole,
  IEntity,
} from '@unfoldrtech/portal-mic'
import React, { useContext } from 'react'
import {
  Container,
  CreativeStatusBadge,
  HoverContainer,
  IconArrowDownSmall,
  SVGContainer,
  StyledAntDropdown,
  StyledAntMenu,
  StyledCircle,
  TooltipWrapper,
} from '../../../Global'
import { isUserAuthorised } from '../../../../utils/userAuthorisation'
import { AdGroupContext, AppContext, TTranslationKey } from '../../../../models'
import useUpdateEntityStatus from '../../../../hooks/useUpdateEntityStatus'
import { useGetRetailerAndAdvertiserIds } from '../../hooks/use-get-retailer-and-advertiser-ids'
import I18NText from '../../../I18NText'
import { ECreativeStatus } from '../../../../models/core'

export const TableDataCellStatusCreativeWithUpdate = ({
  dataValue,
  orgType,
  id,
  isDirectDeal,
  setErrorUpdateStatus,
  setShowUpdateStatusErrorToast,
}: {
  dataValue: ECreativeStatus
  orgType: EOrgType
  id: number
  isDirectDeal: boolean
  setErrorUpdateStatus: (error: TTranslationKey) => void
  setShowUpdateStatusErrorToast: (show: boolean) => void
}) => {
  const [appContext] = useContext(AppContext)
  const [currentDataValue, setCurrentDataValue] = React.useState(dataValue)

  const { retailerId, advertiserId } = useGetRetailerAndAdvertiserIds()
  const adGroup = useContext(AdGroupContext)

  const userRole = appContext.userClaims?.userRole ?? EUserRole.None

  const isMutateNotAllowed = !isUserAuthorised(
    userRole,
    EUserRole.AdvertiserCampaigns
  )
  const isSwitchDisabled = currentDataValue === ECreativeStatus.Disabled
  const showSwitch = orgType === EOrgType.Advertiser
  const isDisabled = isMutateNotAllowed || isSwitchDisabled || !adGroup.id

  const tooltipKey: TTranslationKey = 'entity.disabled'

  const {
    mutate: updateCreativeStatus,
    isLoading: isUpdateCreativeStatusLoading,
    reset: resetUpdateCreativeStatusMutate,
  } = useUpdateEntityStatus({
    advertiserId,
    retailerId,
  })

  return (
    <>
      {[ECreativeStatus.Pending, ECreativeStatus.Rejected].includes(
        dataValue as ECreativeStatus
      ) ? (
        <>
          <CreativeStatusBadge creativeStatus={dataValue as ECreativeStatus} />
        </>
      ) : (
        <TooltipWrapper tooltipKey={tooltipKey} showTooltip={isDisabled}>
          <StyledAntDropdown
            overlay={
              <StyledAntMenu
                onClick={({ key }) => {
                  const currentStatus = currentDataValue
                  const updatedStatus = key as ECreativeStatus
                  setCurrentDataValue(updatedStatus)

                  resetUpdateCreativeStatusMutate()

                  updateCreativeStatus(
                    {
                      id,
                      entityType: EEntityType.AdGroupCreative,
                      entity: {
                        status: updatedStatus as unknown as EStatus,
                        adGroupId: adGroup.id,
                      } as IEntity,
                    },
                    {
                      onError: (error) => {
                        setCurrentDataValue(currentStatus as ECreativeStatus)

                        setShowUpdateStatusErrorToast(true)
                        setErrorUpdateStatus(
                          error?.response?.data
                            .errorKey as unknown as TTranslationKey
                        )
                      },
                    }
                  )
                }}
              >
                <StyledAntMenu.Item key={EStatus.Active}>
                  <Container display="flex" alignItems="center">
                    <StyledCircle
                      backgroundColor="var(--badge-color-active)"
                      width="8px"
                      height="8px"
                    />
                    <Container marginLeft="var(--gutter-default)">
                      <I18NText
                        fontFamily="OpenSans"
                        size="var(--font-size-ps)"
                        textTransform="capitalize"
                        id="active"
                        data-testid="table-status-active-option"
                      />
                    </Container>
                  </Container>
                </StyledAntMenu.Item>
                <StyledAntMenu.Item key={EStatus.Inactive} danger>
                  <Container display="flex" alignItems="center">
                    <StyledCircle
                      backgroundColor="var(--badge-color-inactive)"
                      width="8px"
                      height="8px"
                    />
                    <Container marginLeft="var(--gutter-default)">
                      <I18NText
                        fontFamily="OpenSans"
                        size="var(--font-size-ps)"
                        textTransform="capitalize"
                        id="inactive"
                        data-testid="table-status-inactive-option"
                      />
                    </Container>
                  </Container>
                </StyledAntMenu.Item>
              </StyledAntMenu>
            }
            disabled={
              isUpdateCreativeStatusLoading ||
              isDisabled ||
              !showSwitch ||
              isDirectDeal
            }
            overlayStyle={{ zIndex: 1000 }}
            key={Number(Math.random() * 1000).toString()}
          >
            <HoverContainer
              preset="paragraph opensans semibold"
              display="flex"
              alignItems="center"
              height="100%"
              width="100%"
              cursor="pointer"
              onClick={(e) => {
                e.preventDefault()
              }}
              data-testid="report-table-status-dropdown"
            >
              <CreativeStatusBadge creativeStatus={currentDataValue} />
              {!isDisabled && showSwitch && !isDirectDeal && (
                <SVGContainer
                  preset="paragraph small"
                  margin="0 0 0 var(--margin-default)"
                  className="hover-items"
                >
                  <IconArrowDownSmall />
                </SVGContainer>
              )}
            </HoverContainer>
          </StyledAntDropdown>
        </TooltipWrapper>
      )}
    </>
  )
}
