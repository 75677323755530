import { EChannelTypeGlobal, EDatasetKey } from '../../../../models/core'

export function getCreativesEDatasetKey(
  channelType?: EChannelTypeGlobal,
  adGroup?: boolean
) {
  if (channelType === EChannelTypeGlobal.DisplayAd) {
    return adGroup ? EDatasetKey.CreativeDaAdgroup : EDatasetKey.CreativeDa
  }

  return adGroup ? EDatasetKey.CreativeDoohAdgroup : EDatasetKey.CreativeDooh
}
