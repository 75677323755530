import React, { useState } from 'react'
import { IAdTemplate } from '@unfoldrtech/portal-mic'
import {
  EChannelTypeGlobal,
  IDAReportCreative,
  IDOOHReportCreative,
} from '../../../../models/core'
import {
  Container,
  EllipsisContainer,
  IconCirclePlay,
  TooltipWrapper,
  Text,
  SVGContainer,
} from '../../../Global'
import AdPreview from '../../../AdPreview'
import { VideoPlayerModal } from '../../../../pages/Advertiser/components/VideoPlayerModal'

export const TableDataCellCreative = ({
  dataValue,
  channelType,
}: {
  dataValue: IDAReportCreative | IDOOHReportCreative
  channelType?: EChannelTypeGlobal
}) => {
  const [videoPlayerUrlToast, setVideoPlayerUrlToast] = useState('')

  if (channelType === EChannelTypeGlobal.DigitalOutOfHome) {
    const creative = dataValue as IDOOHReportCreative

    return (
      <Container display="flex">
        <TooltipWrapper showTooltip={true} tooltip={creative.title}>
          <EllipsisContainer maxWidth="240px">
            <Text>{creative.title}</Text>
          </EllipsisContainer>
        </TooltipWrapper>
        <SVGContainer
          preset="paragraph small"
          margin="0 0 0 var(--margin-default)"
          className="hover-items"
          cursor="pointer"
        >
          <IconCirclePlay
            fontSize="var(--icon-size-sm)"
            onClick={() => {
              setVideoPlayerUrlToast(creative.data.url)
            }}
          />
        </SVGContainer>
        <VideoPlayerModal
          show={!!videoPlayerUrlToast}
          url={videoPlayerUrlToast}
          onClose={() => setVideoPlayerUrlToast('')}
        />
      </Container>
    )
  }

  const creative = dataValue as IDAReportCreative

  return (
    <Container
      maxWidth="264px"
      maxHeight="264px"
      display="flex"
      flexDirection="column"
    >
      <Container paddingTop="var(--gutter-1x)" preset="table-title">
        {creative.title}
      </Container>
      <Container display="flex" padding="var(--gutter-1x) 0">
        <Container>
          <AdPreview
            height="150px"
            width="200px"
            adData={creative.data}
            template={creative.template as unknown as IAdTemplate}
          />
        </Container>
      </Container>
    </Container>
  )
}
