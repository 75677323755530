import React, { useContext, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import dayjs from 'dayjs'

import { ReportPageTemplateLayout } from '../../components/ReportPageTemplateLayout'
import { TReportExportType, TReportingFilters } from '../../../../models'
import { AppContext, CampaignContext } from '../../../../models/contexts'
import {
  selectReportingFilters,
  setIsReportingV3,
} from '../../../../store/reportingFilters'
import {
  NOT_AVAILABLE,
  exportFileDateFormat,
} from '../../../../utils/constants'
import { exportTableDataToFile } from '../../helpers'
import { useGetDatasetKeyTableWhitelist } from '../../hooks/use-get-dataset-key-table-whitelist'
import {
  EChannelTypeGlobal,
  IDAReportAdGroupsCreativesTable,
  IDAReportCreativesTable,
  IDOOHReportAdGroupsCreativesTable,
  IDOOHReportCreativesTable,
} from '../../../../models/core'
import {
  convertChannelTypeToParam,
  convertEParamChannelTypeToEChannelTypeGlobal,
} from '../../../../utils/helpers'
import { sanitizeDataTestId } from '../../../../utils'
import { getCreativesEDatasetKey } from '../helpers/get-creatives-e-dataset-key'
import { TDatasetWhitelistKey } from '../../models'
import { ReportingAdGroupsChartWrapper } from './ReportingCreativesChartWrapper'
import { ReportingAdGroupsTableWrapper } from './ReportingCreativesTableWrapper'

type IReportCreativesTable =
  | IDAReportCreativesTable
  | IDAReportAdGroupsCreativesTable
  | IDOOHReportCreativesTable
  | IDOOHReportAdGroupsCreativesTable

export const ReportingCreativesTemplate = ({
  channelType: propChannelType,
}: {
  channelType?: EChannelTypeGlobal
}) => {
  const pageTitle = propChannelType
    ? `reporting.header.creatives.${sanitizeDataTestId(propChannelType)}`
    : 'reporting.header.creatives'

  const intl = useIntl()
  const dispatch = useDispatch()

  const [appContext] = useContext(AppContext)
  const campaign = useContext(CampaignContext)
  const { channelType: reportingFilterChannelType } = useSelector(
    selectReportingFilters
  )
  const channelType =
    propChannelType ||
    convertEParamChannelTypeToEChannelTypeGlobal(
      reportingFilterChannelType ||
        convertChannelTypeToParam(campaign.channelType)
    )

  dispatch(setIsReportingV3({ IsReportingV3: true }))

  const [tableData, setTableData] = useState<IReportCreativesTable>()

  const onTableDataChange = (adGroupsTableData: IReportCreativesTable) => {
    setTableData(adGroupsTableData)
  }
  const whitelistData = useGetDatasetKeyTableWhitelist({
    key:
      (tableData?.datasetKey as TDatasetWhitelistKey) ||
      getCreativesEDatasetKey(channelType),
  })

  const onExportData = (
    { startDate: start, endDate: end }: TReportingFilters,
    type: TReportExportType
  ) => {
    const orgName =
      appContext.userOrg?.name ?? appContext.userOrg?.type ?? NOT_AVAILABLE
    const translatedPageTitle = intl
      .formatMessage({ id: pageTitle })
      .replace(/[-]+/g, '')
      .replace(/[ ]+/g, '_')
    const sDate = dayjs(start).format(exportFileDateFormat)
    const eDate = dayjs(end).format(exportFileDateFormat)

    const fileName = `${orgName}_${translatedPageTitle}_${sDate}_${eDate}`
    if (tableData) {
      exportTableDataToFile({
        tableDataRows: tableData.rows,
        tableHeaders: whitelistData,
        fileName,
        fileType: type,
      })
    }
  }

  return (
    <>
      <ReportPageTemplateLayout
        title={pageTitle}
        isExportDisabled={Boolean(!tableData?.rows.length)}
        chartWrapper={
          <ReportingAdGroupsChartWrapper channelType={channelType} />
        }
        tableWrapper={
          <ReportingAdGroupsTableWrapper
            onTableDataChange={onTableDataChange}
            channelType={channelType}
          />
        }
        onExportData={onExportData}
      />
    </>
  )
}
