import React, { useState, useContext } from 'react'
import Spinner from 'react-bootstrap/Spinner'
import { EUserRole } from '@unfoldrtech/portal-mic'

import {
  CurrencyText,
  Container,
  IconEdit,
  IconButton,
  EllipsisContainer,
  StyledAntPopover,
} from '../../../Global'
import { isUserAuthorised } from '../../../../utils/userAuthorisation'
import { TableDataCellBidPricePopoverContent } from './TableDataCellBidPricePopoverContent'
import {
  CampaignContext,
  EOrgType,
  AppContext,
  EStatus,
} from '../../../../models'
import { useGetIsCurrentCampaignDirectDeal } from '../../hooks/use-get-is-current-campaign-direct-deal'

export const TableDataCellBidPriceContainer = ({
  bidPrice,
  floorPrice,
  orgType,
  status,
  isUpdating,
  onSubmit,
}: {
  bidPrice?: number
  floorPrice?: number
  orgType: EOrgType
  status: EStatus
  isUpdating: boolean
  onSubmit: (bid: number) => void
}) => {
  const [open, setOpen] = useState(false)

  const [appContext] = useContext(AppContext)
  const campaign = useContext(CampaignContext)

  const isDirectDeal = useGetIsCurrentCampaignDirectDeal()

  const userRole = appContext.userClaims?.userRole ?? EUserRole.None
  const isMutateAllowed = isUserAuthorised(
    userRole,
    EUserRole.AdvertiserCampaigns
  )
  const showEditIcon =
    orgType === EOrgType.Advertiser && bidPrice !== undefined && bidPrice >= 0
  const isDisabled =
    !isMutateAllowed || isDirectDeal || status === EStatus.Disabled

  const handleBidPriceSubmit = (bid: number) => {
    onSubmit(bid)
    setOpen(false)
  }

  return (
    <Container display="flex" maxWidth="var(--gutter-10x)">
      <EllipsisContainer flex="1">
        <CurrencyText value={Number(bidPrice)} />
      </EllipsisContainer>

      {showEditIcon && (
        <Container display="inline" marginLeft="var(--gutter-1x)">
          <StyledAntPopover
            destroyTooltipOnHide
            open={open}
            onOpenChange={(visible) => setOpen(visible)}
            showArrow={false}
            trigger="click"
            content={
              <TableDataCellBidPricePopoverContent
                bid={bidPrice!}
                floorPrice={floorPrice!}
                pricingStrategy={campaign.pricingStrategy!}
                handleBidPriceSubmit={handleBidPriceSubmit}
                isUpdating={isUpdating}
              />
            }
          >
            {!isDisabled && (
              <IconButton
                opacity={1}
                onClick={() => setOpen(true)}
                disabled={isDisabled || isUpdating}
              >
                {isUpdating ? (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                ) : (
                  <IconEdit />
                )}
              </IconButton>
            )}
          </StyledAntPopover>
        </Container>
      )}
    </Container>
  )
}
