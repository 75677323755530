import React, { useState, useEffect, ReactNode, useContext } from 'react'
import {
  EEntityType,
  EMatchType,
  EOrgType,
  EParamSortOrder,
  EStatus,
} from '@unfoldrtech/portal-mic'
import { useDispatch, useSelector } from 'react-redux'
import { TReportingFilters, TTranslationKey } from '../../../../models/types'
import { StyledImage } from '../../../Global'
import { selectKeywordsTableReportingFilters } from '../../../../store/reportingFilters'
import {
  selectRefreshTable,
  setRefreshTable,
} from '../../../../store/refreshTable'
import { ReportTableV3 } from '../../components/ReportTableV3'
import { getPlaceholderImage } from '../../helpers/get-placeholder-image'
import { TableDataCellImpressions } from '../../components/table-data-cell/TableDataCellImpressions'
import { TableDataCellCosts } from '../../components/table-data-cell/TableDataCellCosts'
import { useGetRetailerAndAdvertiserIds } from '../../hooks/use-get-retailer-and-advertiser-ids'
import { AdGroupContext, AppContext, CampaignContext } from '../../../../models'
import { TReportTableDataV3 } from '../../models/types/report-table-data-v3.type'
import { useGetDatasetKeyTableWhitelist } from '../../hooks/use-get-dataset-key-table-whitelist'
import { TDAKeywordsTableHeaders, TSPKeywordsTableHeaders } from '../models'
import { TableDataCellCtr } from '../../components/table-data-cell/TableDataCellCtr'
import {
  TDatasetWhitelistKey,
  TGlobalTableDataType,
  TGlobalTables,
} from '../../models'
import { TableDataCellCpc } from '../../components/table-data-cell/TableDataCellCpc'
import { TableDataCellClicks } from '../../components/table-data-cell/TableDataCellClicks'
import { TableDataCellAvgRank } from '../../components/table-data-cell/TableDataCellAvgRank'
import { TableDataCellCarts } from '../../components/table-data-cell/TableDataCellCarts'
import { TableDataCellPurchases } from '../../components/table-data-cell/TableDataCellPurchases'
import { TableDataCellRevenue } from '../../components/table-data-cell/TableDataCellRevenue'
import { TableDataCellRoas } from '../../components/table-data-cell/TableDataCellRoas'
import {
  EChannelTypeGlobal,
  EReportColumnHeader,
  IDAReportKeywordsTable,
  ISPReportKeywordsTable,
} from '../../../../models/core'
import { TableDataCellFrequency } from '../../components/table-data-cell/TableDataCellFrequency'
import { TableDataCellUniqueReach } from '../../components/table-data-cell/TableDataCellUniqueReach'
import { TableDataCellCpm } from '../../components/table-data-cell/TableDataCellCpm'
import { getKeywordsEDatasetKeyFromChannelTypeGlobal } from '../helpers/get-keywords-e-dataset-key-from-channel-type-global'
import { getTableAndChartKeywordsFn } from '../helpers/get-table-and-chart-keywords-fn'
import { TableDataCellNewToBrandPurchases } from '../../components/table-data-cell/TableDataCellNewToBrandPurchases'
import { TableDataCellNewToBrandRevenue } from '../../components/table-data-cell/TableDataCellNewToBrandRevenue'
import { TableDataCellName } from '../../components/table-data-cell/TableDataCellName'
import { TableDataCellStatusKeywordWithUpdate } from '../../components/table-data-cell/TableDataCellStatusKeywordWithUpdate'
import { TableDataCellMatchType } from '../../components/table-data-cell/TableDataCellMatchType'
import { TableDataCellBidPrice } from '../../components/table-data-cell/TableDataCellBidPrice'
import useShowToast from '../../../../hooks/useShowToast'
import { ErrorToast } from '../../../ToastCard/ErrorToast'
import { TablePreloader } from '../../components/table-preloader'
import { useGetDefaultSortBy } from '../../hooks/use-get-default-sort-by'
import useGetKeywordFloorPrices from '../../../../hooks/useGetKeywordFloorPrices'
import { TableDataCellCampaigns } from '../../components/table-data-cell/TableDataCellCampaigns'
import { TableDataCellAdGroups } from '../../components/table-data-cell/TableDataCellAdGroups'

type TKeywordsTableHeaders = TSPKeywordsTableHeaders & TDAKeywordsTableHeaders
type IReportKeywordsTable = ISPReportKeywordsTable | IDAReportKeywordsTable

export const ReportingKeywordsTableWrapper = ({
  onTableDataChange,
  channelType,
}: {
  onTableDataChange: (
    keywordsTableData: ISPReportKeywordsTable | IDAReportKeywordsTable
  ) => void
  channelType: EChannelTypeGlobal
}) => {
  const dispatch = useDispatch()

  const [appContext] = useContext(AppContext)
  const campaign = useContext(CampaignContext)
  const adGroup = useContext(AdGroupContext)

  const orgType = appContext.userOrg!.type

  const { startDate, endDate, timeWindow, tzZone } = useSelector(
    selectKeywordsTableReportingFilters
  )
  const refreshTable = useSelector(selectRefreshTable)
  const { retailerId, advertiserId } = useGetRetailerAndAdvertiserIds()

  // Fetch keywords FloorPrices
  const { data: keywordFloorPricesResponse } = useGetKeywordFloorPrices({
    advertiserId,
    retailerId,
    enabled: orgType === EOrgType.Advertiser,
  })
  const keywordFloorPrice = keywordFloorPricesResponse?.data.find(
    (kfp) => kfp.channelType === campaign.channelType
  )?.floorPrice

  const campaignId = campaign?.id
  const adGroupId = adGroup?.id
  const hookFn = getTableAndChartKeywordsFn({
    orgType,
  })

  const getTableDataFn = hookFn.tableFn!

  const [sortBy, setSortBy] = useState<TKeywordsTableHeaders>()
  const [sortOrder, setSortOrder] = useState<EParamSortOrder>()
  const [tableData, setTableData] = useState<TReportTableDataV3>()

  const [errorUpdate, setErrorUpdate] = useState<TTranslationKey>()
  const [showUpdateErrorToast, setShowUpdateErrorToast] = useShowToast(false)

  useGetDefaultSortBy({
    sortBy,
    sortOrder,
    setSortBy,
    setSortOrder,
    orgType,
    channelType,
  })

  const {
    data: tableDataResponseObj,
    refetch,
    isFetching,
  } = getTableDataFn({
    retailerId,
    advertiserId,
    channelType,
    campaignId,
    adGroupId,
    sortBy,
    timeWindow,
    startDate,
    endDate,
    sortOrder,
    tzZone,
    skip: !!channelType,
    enabled: false,
  })

  const onSortByChange = (sortColumn?: TGlobalTables) => {
    setSortBy(sortColumn as TKeywordsTableHeaders)
  }

  const onSortOrderChange = (
    type: TReportingFilters['sortOrder'] = EParamSortOrder.Desc
  ) => {
    setSortOrder(type)
  }

  const tableDataResponse =
    tableDataResponseObj?.data.table || ({} as IReportKeywordsTable)

  const datasetKey =
    (tableDataResponse.datasetKey as TDatasetWhitelistKey) ||
    getKeywordsEDatasetKeyFromChannelTypeGlobal(channelType)

  const whitelistDataList = useGetDatasetKeyTableWhitelist({
    key: datasetKey,
  })

  const processTableDataResponse = (tableDataRes: IReportKeywordsTable) => {
    const responseHeaders = Array.from(
      new Set(tableDataRes.rows.map((row) => Object.keys(row)).flat())
    )

    const whitelistData = whitelistDataList
      .map((wData) => {
        const whitelistItem = responseHeaders.find(
          (header) => wData.header === header
        )
        if (whitelistItem) {
          return wData
        }
        return undefined
      })
      .filter((wData) => wData) as Array<TGlobalTableDataType>

    const headers = whitelistData.map((wData) => wData.header)

    const processedTableData: TReportTableDataV3 = {
      columnIdList: headers,
      columnNameList: whitelistData.map((wData) => wData.translatedMetricName),
      columnNameTooltipList: whitelistData.map(
        (wData) => wData.translatedTooltipMetricName
      ),
      columnSpanList: headers.map((header) =>
        header === EReportColumnHeader.Name ? 2 : 1
      ),
      rowList: [],
    }

    tableDataRes.rows.forEach((row) => {
      const dataRow: Array<ReactNode | ReactNode[]> = []
      headers.forEach((header) => {
        let dataCell: ReactNode | ReactNode[]
        const dataValue = row[header as TKeywordsTableHeaders]

        switch (header) {
          case EReportColumnHeader.Name:
            dataCell = <TableDataCellName dataValue={dataValue?.toString()} />
            break
          case EReportColumnHeader.Status:
            dataCell = (
              <TableDataCellStatusKeywordWithUpdate
                dataValue={dataValue?.toString() as EStatus}
                matchType={row.match_type as unknown as EMatchType}
                orgType={orgType}
                id={row.id}
                key={`${row.id}-${row.match_type}-${sortBy}-${sortOrder}`}
                setErrorUpdateStatus={setErrorUpdate}
                setShowUpdateStatusErrorToast={setShowUpdateErrorToast}
              />
            )
            break
          case EReportColumnHeader.Campaigns:
            dataCell = (
              <TableDataCellCampaigns dataValue={dataValue as Array<string>} />
            )
            break
          case EReportColumnHeader.Adgroups:
            dataCell = (
              <TableDataCellAdGroups dataValue={dataValue as Array<string>} />
            )
            break
          case EReportColumnHeader.MatchType:
            dataCell = (
              <TableDataCellMatchType dataValue={dataValue as EMatchType} />
            )
            break
          case EReportColumnHeader.BidPrice:
            dataCell = (
              <TableDataCellBidPrice
                orgType={orgType}
                dataValue={Number(dataValue)}
                dataId={row.id}
                floorPrice={keywordFloorPrice}
                status={row.status}
                setErrorUpdate={setErrorUpdate}
                setShowUpdateErrorToast={setShowUpdateErrorToast}
              />
            )
            break
          case EReportColumnHeader.Impressions:
            dataCell = (
              <TableDataCellImpressions dataValue={Number(dataValue)} />
            )
            break
          case EReportColumnHeader.Clicks:
            dataCell = <TableDataCellClicks dataValue={Number(dataValue)} />
            break
          case EReportColumnHeader.Ctr:
            dataCell = <TableDataCellCtr dataValue={Number(dataValue)} />
            break
          case EReportColumnHeader.Costs:
            dataCell = <TableDataCellCosts dataValue={Number(dataValue)} />
            break
          case EReportColumnHeader.Carts:
            dataCell = <TableDataCellCarts dataValue={Number(dataValue)} />
            break
          case EReportColumnHeader.Purchases:
            dataCell = <TableDataCellPurchases dataValue={Number(dataValue)} />
            break
          case EReportColumnHeader.Revenue:
            dataCell = <TableDataCellRevenue dataValue={Number(dataValue)} />
            break
          case EReportColumnHeader.Roas:
            dataCell = <TableDataCellRoas dataValue={Number(dataValue)} />
            break
          // SP Exclusive
          case EReportColumnHeader.Cpc:
            dataCell = <TableDataCellCpc dataValue={Number(dataValue)} />
            break
          case EReportColumnHeader.AvgRank:
            dataCell = <TableDataCellAvgRank dataValue={Number(dataValue)} />
            break
          case EReportColumnHeader.NewToBrandPurchases:
            dataCell = (
              <TableDataCellNewToBrandPurchases dataValue={Number(dataValue)} />
            )
            break
          case EReportColumnHeader.NewToBrandRevenue:
            dataCell = (
              <TableDataCellNewToBrandRevenue dataValue={Number(dataValue)} />
            )
            break
          // DA Exclusive
          case EReportColumnHeader.Frequency:
            dataCell = <TableDataCellFrequency dataValue={Number(dataValue)} />
            break
          case EReportColumnHeader.UniqueReach:
            dataCell = (
              <TableDataCellUniqueReach dataValue={Number(dataValue)} />
            )
            break
          case EReportColumnHeader.PvCarts:
            dataCell = <TableDataCellCarts dataValue={Number(dataValue)} />
            break
          case EReportColumnHeader.PvPurchases:
            dataCell = <TableDataCellPurchases dataValue={Number(dataValue)} />
            break
          case EReportColumnHeader.PvRevenue:
            dataCell = <TableDataCellRevenue dataValue={Number(dataValue)} />
            break
          case EReportColumnHeader.Cpm:
            dataCell = <TableDataCellCpm dataValue={Number(dataValue)} />
            break

          default:
            dataCell = undefined
            break
        }

        if (dataCell) {
          dataRow.push(dataCell)
        }
      })

      processedTableData.rowList.push(dataRow)
    })

    return processedTableData
  }

  useEffect(() => {
    if (startDate && endDate && sortBy && sortOrder) {
      refetch()
    }
  }, [startDate, endDate, sortBy, sortOrder])

  useEffect(() => {
    if (refreshTable) {
      refetch()
      dispatch(setRefreshTable({ refreshTable: false }))
    }
  }, [refreshTable])

  useEffect(() => {
    if (Object.keys(tableDataResponse).length > 0) {
      setTableData(processTableDataResponse(tableDataResponse))
      onTableDataChange(
        tableDataResponse as ISPReportKeywordsTable & IDAReportKeywordsTable
      )
    }
  }, [tableDataResponse])

  return (
    <>
      {tableData?.rowList?.length &&
        tableData?.rowList?.length > 0 &&
        !isFetching && (
          <>
            {tableData?.rowList && (
              <ReportTableV3
                {...tableData}
                sortBy={sortBy}
                sortOrder={sortOrder}
                setSortBy={onSortByChange}
                setSortOrder={onSortOrderChange}
              />
            )}
          </>
        )}
      {tableData?.rowList?.length === 0 && (
        <StyledImage
          fluid
          src={getPlaceholderImage(EEntityType.AdGroupKeyword, orgType)}
          alt="home_placeholder"
          cursor="default"
        />
      )}

      <ErrorToast
        show={showUpdateErrorToast}
        onClose={() => {
          setShowUpdateErrorToast(false)
          setErrorUpdate(undefined)
        }}
        translationKey={errorUpdate}
      />

      {isFetching && <TablePreloader />}
    </>
  )
}
