import { useQuery } from 'react-query'
import { AxiosError, AxiosResponse } from 'axios'
import { IError } from '@unfoldrtech/portal-mic'
import { generateCorrelationId, getTimezone } from '../utils/helpers'
import {
  TDAAdGroupsCreativesReportChartFilters,
  TDACreativesReportChartFilters,
  TDOOHAdGroupsCreativesReportChartFilters,
  TDOOHCreativesReportChartFilters,
  TPPRetailerId,
} from '../models/types'
import { EReportingTimeWindow } from '../models'
import {
  ETimeBucketType,
  IReportingCreativesChartResponse,
} from '../models/core'
import { coreAPIV3 } from '../services/coreAPIV3'

export default function useGetRetailerReportingCreativesChartV3({
  retailerId,
  advertiserId,
  inventoryType,
  campaignId,
  adGroupId,
  timeWindow,
  startDate,
  endDate,
  tzZone,
  aggregationType,
  enabled = true,
  suspense = true,
}:
  | {
      enabled?: boolean
      suspense?: boolean
    } & (
      | TDACreativesReportChartFilters
      | TDAAdGroupsCreativesReportChartFilters
      | TDOOHCreativesReportChartFilters
      | TDOOHAdGroupsCreativesReportChartFilters
    ) &
      TPPRetailerId) {
  const queryKeyName = `getRetailerReportingCreativesChart_${retailerId}_${advertiserId}`

  return useQuery<
    AxiosResponse<IReportingCreativesChartResponse>,
    AxiosError<IError>
  >(
    [
      queryKeyName,
      { start: startDate, end: endDate, campaignId, inventoryType },
    ],
    () => {
      return coreAPIV3
        .retailerReporting()
        .getRetailerCreativesReportChartV3(
          retailerId,
          generateCorrelationId(),
          advertiserId,
          campaignId,
          adGroupId,
          timeWindow as unknown as EReportingTimeWindow,
          startDate,
          endDate,
          tzZone || getTimezone(),
          aggregationType as ETimeBucketType
        )
    },
    { suspense, enabled }
  )
}
