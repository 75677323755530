import { EReportColumnHeader } from '../../../../../models/core'
import { TGlobalMetrics } from '../../../models'

export const doohMetricNames: Array<TGlobalMetrics> = [
  EReportColumnHeader.Playouts,
  EReportColumnHeader.Impressions,
  EReportColumnHeader.Costs,
  EReportColumnHeader.Cpm,
  EReportColumnHeader.ShareOfVoice,
  EReportColumnHeader.WinRate,
  // EReportColumnHeader.AvgRank, // not available yet
]
