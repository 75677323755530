import { EReportColumnHeader } from '../../../../../models/core'
import { TGlobalTables } from '../../../models'

export const spMetricHeaders: Array<TGlobalTables> = [
  EReportColumnHeader.Impressions,
  EReportColumnHeader.Clicks,
  EReportColumnHeader.Ctr,
  EReportColumnHeader.Costs,
  EReportColumnHeader.Cpc,
  EReportColumnHeader.ShareOfVoice,
  EReportColumnHeader.WinRate,
  EReportColumnHeader.AvgRank,
  EReportColumnHeader.Carts,
  EReportColumnHeader.Purchases,
  EReportColumnHeader.Revenue,
  EReportColumnHeader.Roas,
  EReportColumnHeader.NewToBrandPurchases,
  EReportColumnHeader.NewToBrandRevenue,
]
