import React, { useState, useEffect, ReactNode, useContext } from 'react'
import {
  EChannelType,
  EEntityType,
  EParamSortOrder,
  EStatus,
} from '@unfoldrtech/portal-mic'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { TReportingFilters, TTranslationKey } from '../../../../models/types'
import { StyledImage } from '../../../Global'
import { selectAdGroupsTableReportingFilters } from '../../../../store/reportingFilters'
import {
  selectRefreshTable,
  setRefreshTable,
} from '../../../../store/refreshTable'
import { ReportTableV3 } from '../../components/ReportTableV3'
import { getPlaceholderImage } from '../../helpers/get-placeholder-image'
import { TableDataCellImpressions } from '../../components/table-data-cell/TableDataCellImpressions'
import { TableDataCellCosts } from '../../components/table-data-cell/TableDataCellCosts'
import { useGetRetailerAndAdvertiserIds } from '../../hooks/use-get-retailer-and-advertiser-ids'
import { useGetDefaultSortBy } from '../../hooks/use-get-default-sort-by'
import { AppContext, CampaignContext, EDealType } from '../../../../models'
import { TReportTableDataV3 } from '../../models/types/report-table-data-v3.type'
import { useGetDatasetKeyTableWhitelist } from '../../hooks/use-get-dataset-key-table-whitelist'
import {
  TDAAdGroupsPositionsTableHeaders,
  TDAAdGroupsTableHeaders,
  TDOOHAdGroupsScreensTableHeaders,
  TDOOHAdGroupsTableHeaders,
  TGenericAdGroupsCategoriesTableHeaders,
  TGenericAdGroupsKeywordsTableHeaders,
  TGenericAdGroupsTableHeaders,
  TSPAdGroupsTableHeaders,
} from '../models'
import { TableDataCellCtr } from '../../components/table-data-cell/TableDataCellCtr'
import {
  TDatasetWhitelistKey,
  TGlobalTableDataType,
  TGlobalTables,
} from '../../models'
import { TableDataCellClicks } from '../../components/table-data-cell/TableDataCellClicks'
import { TableDataCellAvgRank } from '../../components/table-data-cell/TableDataCellAvgRank'
import { TableDataCellCarts } from '../../components/table-data-cell/TableDataCellCarts'
import { TableDataCellPurchases } from '../../components/table-data-cell/TableDataCellPurchases'
import { TableDataCellRevenue } from '../../components/table-data-cell/TableDataCellRevenue'
import { TableDataCellRoas } from '../../components/table-data-cell/TableDataCellRoas'
import {
  EChannelTypeGlobal,
  EInventoryTypeGlobal,
  EReportColumnHeader,
  IDAReportAdGroupsPositionsTable,
  IDAReportAdGroupsTable,
  IDAReportCampaignsTable,
  IDOOHReportAdGroupsScreensTable,
  IDOOHReportAdGroupsTable,
  IGenericReportAdGroupsCategoriesTable,
  IGenericReportAdGroupsKeywordsTable,
  IGenericReportAdGroupsTable,
  ISPReportAdGroupsTable,
  ISPReportCampaignsTable,
} from '../../../../models/core'
import { TableDataCellFrequency } from '../../components/table-data-cell/TableDataCellFrequency'
import { TableDataCellUniqueReach } from '../../components/table-data-cell/TableDataCellUniqueReach'
import { TableDataCellCpm } from '../../components/table-data-cell/TableDataCellCpm'
import { getAdGroupsEDatasetKey } from '../helpers/get-ad-groups-e-dataset-key'
import { getTableAndChartAdGroupsFn } from '../helpers/get-table-and-chart-ad-groups-fn'
import { TableDataCellChannelType } from '../../components/table-data-cell/TableDataCellChannelType'
import { TableDataCellShareOfVoice } from '../../components/table-data-cell/TableDataCellShareOfVoice'
import { TableDataCellWinRate } from '../../components/table-data-cell/TableDataCellWinRate'
import { TableDataCellNewToBrandPurchases } from '../../components/table-data-cell/TableDataCellNewToBrandPurchases'
import { TableDataCellNewToBrandRevenue } from '../../components/table-data-cell/TableDataCellNewToBrandRevenue'
import { TableDataCellPlayouts } from '../../components/table-data-cell/TableDataCellPlayouts'
import useShowToast from '../../../../hooks/useShowToast'
import { ErrorToast } from '../../../ToastCard/ErrorToast'
import { TablePreloader } from '../../components/table-preloader'
import { TableDataCellAdGroupName } from '../../components/table-data-cell/TableDataCellAdGroupName'
import { TableDataCellStatusAdGroupWithUpdate } from '../../components/table-data-cell/TableDataCellStatusAdGroupWithUpdate'
import { TableDataCellPlacement } from '../../components/table-data-cell/TableDataCellPlacement'
import { TableDataCellScreenPositionName } from '../../components/table-data-cell/TableDataCellScreenPositionName'
import { TableDataCellCpc } from '../../components/table-data-cell/TableDataCellCpc'
import { selectDoohCampaignAdGroups } from '../../../../store/doohCampaign'
import { TableDataCellAdGroupBidPrice } from '../../components/table-data-cell/TableDataCellAdGroupBidPrice'

type TAdGroupsTableHeaders = TGenericAdGroupsTableHeaders &
  TGenericAdGroupsCategoriesTableHeaders &
  TGenericAdGroupsKeywordsTableHeaders &
  TSPAdGroupsTableHeaders &
  TDAAdGroupsTableHeaders &
  TDAAdGroupsPositionsTableHeaders &
  TDOOHAdGroupsTableHeaders &
  TDOOHAdGroupsScreensTableHeaders

type IReportAdGroupsTable =
  | IGenericReportAdGroupsTable
  | IGenericReportAdGroupsCategoriesTable
  | IGenericReportAdGroupsKeywordsTable
  | ISPReportAdGroupsTable
  | IDAReportAdGroupsTable
  | IDAReportAdGroupsPositionsTable
  | IDOOHReportAdGroupsTable
  | IDOOHReportAdGroupsScreensTable

export const ReportingAdGroupsTableWrapper = ({
  onTableDataChange,
  inventoryType,
  channelType,
}: {
  onTableDataChange: (adGroupsTableData: IReportAdGroupsTable) => void
  inventoryType?: EInventoryTypeGlobal
  channelType?: EChannelTypeGlobal
}) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [appContext] = useContext(AppContext)
  const campaign = useContext(CampaignContext)

  const doohCampaignAdGroups = useSelector(selectDoohCampaignAdGroups)

  const getDoohAdGroupFloorPrice = (adGroupId: number) => {
    const currentAdGroup = doohCampaignAdGroups.filter(
      (a) => a.id === adGroupId
    )[0]

    return currentAdGroup?.targeting.audiencesList.length &&
      currentAdGroup?.targeting.screenPosition.audienceFloorPrice
      ? currentAdGroup?.targeting.screenPosition.audienceFloorPrice
      : currentAdGroup?.targeting.screenPosition.baseFloorPrice
  }

  const { startDate, endDate, timeWindow, tzZone } = useSelector(
    selectAdGroupsTableReportingFilters
  )
  const refreshTable = useSelector(selectRefreshTable)
  const { retailerId, advertiserId } = useGetRetailerAndAdvertiserIds()

  const orgType = appContext.userOrg!.type
  const hookFn = getTableAndChartAdGroupsFn({
    orgType,
  })

  const getTableDataFn = hookFn.tableFn!

  const [sortBy, setSortBy] = useState<TAdGroupsTableHeaders>()
  const [sortOrder, setSortOrder] = useState<EParamSortOrder>()
  const [tableData, setTableData] = useState<TReportTableDataV3>()

  const [errorUpdate, setErrorUpdate] = useState<TTranslationKey>()
  const [showUpdateErrorToast, setShowUpdateErrorToast] = useShowToast(false)

  useGetDefaultSortBy({
    sortBy,
    sortOrder,
    setSortBy,
    setSortOrder,
    orgType,
    channelType,
  })

  const {
    data: tableDataResponseObj,
    refetch,
    isFetching,
  } = getTableDataFn({
    retailerId,
    advertiserId,
    campaignId: campaign?.id,
    inventoryType,
    channelType,
    sortBy,
    timeWindow,
    startDate,
    endDate,
    sortOrder,
    tzZone,
    enabled: false,
  })

  const onSortByChange = (sortColumn?: TGlobalTables) => {
    setSortBy(sortColumn as TAdGroupsTableHeaders)
  }

  const onSortOrderChange = (
    type: TReportingFilters['sortOrder'] = EParamSortOrder.Desc
  ) => {
    setSortOrder(type)
  }

  const tableDataResponse =
    tableDataResponseObj?.data.table || ({} as IReportAdGroupsTable)

  const whitelistDataList = useGetDatasetKeyTableWhitelist({
    key:
      (tableDataResponse.datasetKey as TDatasetWhitelistKey) ||
      getAdGroupsEDatasetKey(inventoryType, channelType),
  })

  const processTableDataResponse = (tableDataRes: IReportAdGroupsTable) => {
    const responseHeaders = Array.from(
      new Set(tableDataRes.rows.map((row) => Object.keys(row)).flat())
    )

    const whitelistData = whitelistDataList
      .map((wData) => {
        const whitelistItem = responseHeaders.find(
          (header) => wData.header === header
        )
        if (whitelistItem) {
          return wData
        }
        return undefined
      })
      .filter((wData) => wData) as Array<TGlobalTableDataType>

    const headers = whitelistData.map((wData) => wData.header)

    const processedTableData: TReportTableDataV3 = {
      columnIdList: headers,
      columnNameList: whitelistData.map((wData) => wData.translatedMetricName),
      columnNameTooltipList: whitelistData.map(
        (wData) => wData.translatedTooltipMetricName
      ),
      columnSpanList: headers.map((header) =>
        header === EReportColumnHeader.Name ? 2 : 1
      ),
      rowList: [],
    }

    tableDataRes.rows.forEach((row) => {
      const dataRow: Array<ReactNode | ReactNode[]> = []
      const floorPrice = Object.values(EChannelType).includes(
        campaign.channelType
      )
        ? 0
        : getDoohAdGroupFloorPrice(row.id)

      headers.forEach((header) => {
        let dataCell: ReactNode | ReactNode[]
        const dataValue = row[header as TAdGroupsTableHeaders]
        const navLink = `../adgroups/${row.id}`

        switch (header) {
          case EReportColumnHeader.Name:
            dataCell = (
              <TableDataCellAdGroupName
                dataValue={dataValue?.toString()}
                navigate={() => {
                  navigate(navLink, { state: row })
                }}
              />
            )
            break
          case EReportColumnHeader.Status:
            dataCell = (
              <TableDataCellStatusAdGroupWithUpdate
                dataValue={dataValue?.toString() as EStatus}
                orgType={orgType}
                id={row.id}
                isDirectDeal={row.campaign_type !== EDealType.Programmatic}
                setErrorUpdateStatus={setErrorUpdate}
                setShowUpdateStatusErrorToast={setShowUpdateErrorToast}
              />
            )
            break
          case EReportColumnHeader.InventoryType:
            dataCell = (
              <TableDataCellPlacement dataValue={dataValue?.toString()} />
            )
            break
          case EReportColumnHeader.ScreenPositionName:
            dataCell = (
              <TableDataCellScreenPositionName
                dataValue={dataValue?.toString()}
              />
            )
            break
          case EReportColumnHeader.ChannelType:
            dataCell = (
              <TableDataCellChannelType
                dataValue={dataValue as EChannelTypeGlobal}
              />
            )
            break
          case EReportColumnHeader.BidPrice:
            dataCell = (
              <TableDataCellAdGroupBidPrice
                orgType={orgType}
                dataValue={Number(dataValue)}
                dataId={row.id}
                floorPrice={floorPrice}
                status={row.status}
                setErrorUpdate={setErrorUpdate}
                setShowUpdateErrorToast={setShowUpdateErrorToast}
              />
            )
            break
          case EReportColumnHeader.Playouts:
            dataCell = <TableDataCellPlayouts dataValue={Number(dataValue)} />
            break
          case EReportColumnHeader.Impressions:
            dataCell = (
              <TableDataCellImpressions dataValue={Number(dataValue)} />
            )
            break
          case EReportColumnHeader.Clicks:
            dataCell = <TableDataCellClicks dataValue={Number(dataValue)} />
            break
          case EReportColumnHeader.Ctr:
            dataCell = <TableDataCellCtr dataValue={Number(dataValue)} />
            break
          case EReportColumnHeader.UniqueReach:
            dataCell = (
              <TableDataCellUniqueReach dataValue={Number(dataValue)} />
            )
            break
          case EReportColumnHeader.Frequency:
            dataCell = <TableDataCellFrequency dataValue={Number(dataValue)} />
            break
          case EReportColumnHeader.Costs:
            dataCell = <TableDataCellCosts dataValue={Number(dataValue)} />
            break
          case EReportColumnHeader.ShareOfVoice:
            dataCell = (
              <TableDataCellShareOfVoice dataValue={Number(dataValue)} />
            )
            break
          case EReportColumnHeader.WinRate:
            dataCell = <TableDataCellWinRate dataValue={Number(dataValue)} />
            break
          case EReportColumnHeader.Carts:
            dataCell = <TableDataCellCarts dataValue={Number(dataValue)} />
            break
          case EReportColumnHeader.PvCarts:
            dataCell = <TableDataCellCarts dataValue={Number(dataValue)} />
            break
          case EReportColumnHeader.Purchases:
            dataCell = <TableDataCellPurchases dataValue={Number(dataValue)} />
            break
          case EReportColumnHeader.PvPurchases:
            dataCell = <TableDataCellPurchases dataValue={Number(dataValue)} />
            break
          case EReportColumnHeader.Revenue:
            dataCell = <TableDataCellRevenue dataValue={Number(dataValue)} />
            break
          case EReportColumnHeader.PvRevenue:
            dataCell = <TableDataCellRevenue dataValue={Number(dataValue)} />
            break
          case EReportColumnHeader.NewToBrandPurchases:
            dataCell = (
              <TableDataCellNewToBrandPurchases dataValue={Number(dataValue)} />
            )
            break
          case EReportColumnHeader.NewToBrandRevenue:
            dataCell = (
              <TableDataCellNewToBrandRevenue dataValue={Number(dataValue)} />
            )
            break
          case EReportColumnHeader.Roas:
            dataCell = <TableDataCellRoas dataValue={Number(dataValue)} />
            break
          case EReportColumnHeader.Cpc:
            dataCell = <TableDataCellCpc dataValue={Number(dataValue)} />
            break
          case EReportColumnHeader.Cpm:
            dataCell = <TableDataCellCpm dataValue={Number(dataValue)} />
            break
          case EReportColumnHeader.AvgRank:
            dataCell = <TableDataCellAvgRank dataValue={Number(dataValue)} />
            break

          default:
            dataCell = undefined
            break
        }

        if (dataCell) {
          dataRow.push(dataCell)
        }
      })

      processedTableData.rowList.push(dataRow)
    })

    return processedTableData
  }

  useEffect(() => {
    if (sortBy && sortOrder && startDate && endDate) {
      refetch()
    }
  }, [sortBy, sortOrder, startDate, endDate, inventoryType])

  useEffect(() => {
    if (refreshTable) {
      refetch()
      dispatch(setRefreshTable({ refreshTable: false }))
    }
  }, [refreshTable])

  useEffect(() => {
    if (Object.keys(tableDataResponse).length > 0) {
      setTableData(processTableDataResponse(tableDataResponse))
      onTableDataChange(
        tableDataResponse as ISPReportCampaignsTable & IDAReportCampaignsTable
      )
    }
  }, [tableDataResponse])

  return (
    <>
      {tableData?.rowList?.length &&
        tableData?.rowList?.length > 0 &&
        !isFetching && (
          <>
            {tableData?.rowList && (
              <ReportTableV3
                {...tableData}
                sortBy={sortBy}
                sortOrder={sortOrder}
                setSortBy={onSortByChange}
                setSortOrder={onSortOrderChange}
              />
            )}
          </>
        )}
      {tableData?.rowList?.length === 0 && (
        <StyledImage
          fluid
          src={getPlaceholderImage(EEntityType.Campaign, orgType)}
          alt="home_placeholder"
          cursor="default"
        />
      )}

      <ErrorToast
        show={showUpdateErrorToast}
        onClose={() => {
          setShowUpdateErrorToast(false)
          setErrorUpdate(undefined)
        }}
        translationKey={errorUpdate}
      />

      {isFetching && <TablePreloader />}
    </>
  )
}
