import {
  EReportColumnHeader,
  EReportColumnType,
  EReportingMetricNumberType,
} from '../../../../models/core'
import { TGlobalMetrics, TGlobalTables } from '../../models'

type TMetricNumberAggregationType = {
  numberType: EReportColumnType
  aggregationType: EReportingMetricNumberType
}
export const metricTypeMap = {} as Record<
  TGlobalMetrics | TGlobalTables,
  TMetricNumberAggregationType
>

metricTypeMap[EReportColumnHeader.Impressions] = {
  numberType: EReportColumnType.Number,
  aggregationType: EReportingMetricNumberType.Sum,
}
metricTypeMap[EReportColumnHeader.Clicks] = {
  numberType: EReportColumnType.Number,
  aggregationType: EReportingMetricNumberType.Sum,
}
metricTypeMap[EReportColumnHeader.Ctr] = {
  numberType: EReportColumnType.Percentage,
  aggregationType: EReportingMetricNumberType.Avg,
}
metricTypeMap[EReportColumnHeader.Costs] = {
  numberType: EReportColumnType.Currency,
  aggregationType: EReportingMetricNumberType.Sum,
}
metricTypeMap[EReportColumnHeader.ShareOfVoice] = {
  numberType: EReportColumnType.Percentage,
  aggregationType: EReportingMetricNumberType.Avg,
}
metricTypeMap[EReportColumnHeader.WinRate] = {
  numberType: EReportColumnType.Percentage,
  aggregationType: EReportingMetricNumberType.Avg,
}
metricTypeMap[EReportColumnHeader.Carts] = {
  numberType: EReportColumnType.Number,
  aggregationType: EReportingMetricNumberType.Sum,
}
metricTypeMap[EReportColumnHeader.Purchases] = {
  numberType: EReportColumnType.Number,
  aggregationType: EReportingMetricNumberType.Sum,
}
metricTypeMap[EReportColumnHeader.Revenue] = {
  numberType: EReportColumnType.Currency,
  aggregationType: EReportingMetricNumberType.Sum,
}
metricTypeMap[EReportColumnHeader.NewToBrandPurchases] = {
  numberType: EReportColumnType.Number,
  aggregationType: EReportingMetricNumberType.Sum,
}
metricTypeMap[EReportColumnHeader.NewToBrandRevenue] = {
  numberType: EReportColumnType.Currency,
  aggregationType: EReportingMetricNumberType.Sum,
}
metricTypeMap[EReportColumnHeader.Roas] = {
  numberType: EReportColumnType.Number,
  aggregationType: EReportingMetricNumberType.Avg,
}
metricTypeMap[EReportColumnHeader.Cpc] = {
  numberType: EReportColumnType.Currency,
  aggregationType: EReportingMetricNumberType.Avg,
}
metricTypeMap[EReportColumnHeader.AvgRank] = {
  numberType: EReportColumnType.Number,
  aggregationType: EReportingMetricNumberType.Avg,
}
metricTypeMap[EReportColumnHeader.UniqueReach] = {
  numberType: EReportColumnType.Number,
  aggregationType: EReportingMetricNumberType.Sum,
}
metricTypeMap[EReportColumnHeader.Frequency] = {
  numberType: EReportColumnType.Number,
  aggregationType: EReportingMetricNumberType.Avg,
}
metricTypeMap[EReportColumnHeader.Cpm] = {
  numberType: EReportColumnType.Currency,
  aggregationType: EReportingMetricNumberType.Avg,
}
metricTypeMap[EReportColumnHeader.PvCarts] = {
  numberType: EReportColumnType.Number,
  aggregationType: EReportingMetricNumberType.Sum,
}
metricTypeMap[EReportColumnHeader.PvPurchases] = {
  numberType: EReportColumnType.Number,
  aggregationType: EReportingMetricNumberType.Sum,
}
metricTypeMap[EReportColumnHeader.PvRevenue] = {
  numberType: EReportColumnType.Currency,
  aggregationType: EReportingMetricNumberType.Sum,
}
metricTypeMap[EReportColumnHeader.Playouts] = {
  numberType: EReportColumnType.Number,
  aggregationType: EReportingMetricNumberType.Sum,
}
