import { PayloadAction } from '@reduxjs/toolkit'
import {
  EReportColumnHeader,
  TDOOHLocationsReportTableFilters,
} from '../../../models'

const setSortByV3 = (
  state: TDOOHLocationsReportTableFilters,
  action: PayloadAction<{
    sortBy?: EReportColumnHeader
  }>
) => {
  state.sortBy = action.payload.sortBy
}

export default setSortByV3
