import { EReportColumnHeader } from '../../../../../models/core'
import { TGlobalMetrics } from '../../../models'
import { daMetricNames } from './da-metric-names'

export const daProductMetricNames: Array<TGlobalMetrics> = daMetricNames.filter(
  (header) =>
    !(
      header === EReportColumnHeader.ShareOfVoice ||
      header === EReportColumnHeader.WinRate ||
      header === EReportColumnHeader.AvgRank ||
      header === EReportColumnHeader.Frequency
    )
)
