import * as React from 'react'
import { SVGProps } from 'react'

const SvgIconPositionForecasting = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 140 118"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M57.696 95H19.304C18.032 95 17 96.552 17 98.465c0 1.913 1.032 3.465 2.304 3.465h38.392c1.272 0 2.304-1.552 2.304-3.465C60 96.552 58.968 95 57.696 95ZM117.696 68H79.304C78.032 68 77 69.552 77 71.465c0 1.913 1.032 3.465 2.304 3.465h38.392c1.272 0 2.304-1.552 2.304-3.465 0-1.913-1.032-3.465-2.304-3.465Z"
      fill="currentColor"
    />
    <rect
      x={20.5}
      y={43.5}
      width={36}
      height={37}
      rx={3.5}
      stroke="currentColor"
      strokeWidth={7}
    />
    <rect
      x={80.5}
      y={43.5}
      width={36}
      height={54}
      rx={3.5}
      stroke="currentColor"
      strokeWidth={7}
    />
    <rect
      x={3.5}
      y={3.5}
      width={133}
      height={111}
      rx={4.5}
      stroke="currentColor"
      strokeWidth={7}
    />
    <path stroke="currentColor" strokeWidth={7} d="M0 24.5h140" />
  </svg>
)
export default SvgIconPositionForecasting
