import React, { useState, useEffect, ReactNode, useContext } from 'react'
import {
  EEntityType,
  EOrgType,
  EParamAdvertiserTargetType,
  EParamSortOrder,
  EStatus,
  IPermittedCategoriesResponse,
} from '@unfoldrtech/portal-mic'
import { useDispatch, useSelector } from 'react-redux'
import { TReportingFilters, TTranslationKey } from '../../../../models/types'
import { StyledImage } from '../../../Global'
import { selectCategoriesTableReportingFilters } from '../../../../store/reportingFilters'
import {
  selectRefreshTable,
  setRefreshTable,
} from '../../../../store/refreshTable'
import { ReportTableV3 } from '../../components/ReportTableV3'
import { getPlaceholderImage } from '../../helpers/get-placeholder-image'
import { TableDataCellImpressions } from '../../components/table-data-cell/TableDataCellImpressions'
import { TableDataCellCosts } from '../../components/table-data-cell/TableDataCellCosts'
import { useGetRetailerAndAdvertiserIds } from '../../hooks/use-get-retailer-and-advertiser-ids'
import { AdGroupContext, AppContext, CampaignContext } from '../../../../models'
import { TReportTableDataV3 } from '../../models/types/report-table-data-v3.type'
import { useGetDatasetKeyTableWhitelist } from '../../hooks/use-get-dataset-key-table-whitelist'
import { TDACategoriesTableHeaders, TSPCategoriesTableHeaders } from '../models'
import { TableDataCellCtr } from '../../components/table-data-cell/TableDataCellCtr'
import {
  TDatasetWhitelistKey,
  TGlobalTableDataType,
  TGlobalTables,
} from '../../models'
import { TableDataCellCpc } from '../../components/table-data-cell/TableDataCellCpc'
import { TableDataCellClicks } from '../../components/table-data-cell/TableDataCellClicks'
import { TableDataCellAvgRank } from '../../components/table-data-cell/TableDataCellAvgRank'
import { TableDataCellCarts } from '../../components/table-data-cell/TableDataCellCarts'
import { TableDataCellPurchases } from '../../components/table-data-cell/TableDataCellPurchases'
import { TableDataCellRevenue } from '../../components/table-data-cell/TableDataCellRevenue'
import { TableDataCellRoas } from '../../components/table-data-cell/TableDataCellRoas'
import {
  EChannelTypeGlobal,
  EReportColumnHeader,
  IDAReportCategoriesTable,
  ISPReportCategoriesTable,
} from '../../../../models/core'
import { TableDataCellFrequency } from '../../components/table-data-cell/TableDataCellFrequency'
import { TableDataCellUniqueReach } from '../../components/table-data-cell/TableDataCellUniqueReach'
import { TableDataCellCpm } from '../../components/table-data-cell/TableDataCellCpm'
import { getCategoriesEDatasetKeyFromChannelTypeGlobal } from '../helpers/get-categories-e-dataset-key-from-channel-type-global'
import { getTableAndChartCategoriesFn } from '../helpers/get-table-and-chart-categories-fn'
import { TableDataCellNewToBrandPurchases } from '../../components/table-data-cell/TableDataCellNewToBrandPurchases'
import { TableDataCellNewToBrandRevenue } from '../../components/table-data-cell/TableDataCellNewToBrandRevenue'
import { TableDataCellName } from '../../components/table-data-cell/TableDataCellName'
import { TableDataCellStatusCategoryWithUpdate } from '../../components/table-data-cell/TableDataCellStatusCategoryWithUpdate'
import { TableDataCellBidPrice } from '../../components/table-data-cell/TableDataCellBidPrice'
import { TableDataCellShareOfVoice } from '../../components/table-data-cell/TableDataCellShareOfVoice'
import { TableDataCellWinRate } from '../../components/table-data-cell/TableDataCellWinRate'
import useShowToast from '../../../../hooks/useShowToast'
import { ErrorToast } from '../../../ToastCard/ErrorToast'
import { TablePreloader } from '../../components/table-preloader'
import { useGetDefaultSortBy } from '../../hooks/use-get-default-sort-by'
import useGetAdvertiserTargets from '../../../../hooks/useGetAdvertiserTargets'
import { convertChannelTypeToParam } from '../../../../utils/helpers'
import { TableDataCellCampaigns } from '../../components/table-data-cell/TableDataCellCampaigns'
import { TableDataCellAdGroups } from '../../components/table-data-cell/TableDataCellAdGroups'

type TCategoriesTableHeaders = TSPCategoriesTableHeaders &
  TDACategoriesTableHeaders
type IReportCategoriesTable =
  | ISPReportCategoriesTable
  | IDAReportCategoriesTable

export const ReportingCategoriesTableWrapper = ({
  onTableDataChange,
  channelType,
}: {
  onTableDataChange: (
    categoriesTableData: ISPReportCategoriesTable | IDAReportCategoriesTable
  ) => void
  channelType: EChannelTypeGlobal
}) => {
  const dispatch = useDispatch()

  const [appContext] = useContext(AppContext)
  const campaign = useContext(CampaignContext)
  const adGroup = useContext(AdGroupContext)

  const orgType = appContext.userOrg!.type

  const { startDate, endDate, timeWindow, tzZone } = useSelector(
    selectCategoriesTableReportingFilters
  )

  const refreshTable = useSelector(selectRefreshTable)
  const { retailerId, advertiserId } = useGetRetailerAndAdvertiserIds()

  const { data: categoriesData } = useGetAdvertiserTargets({
    advertiserId,
    retailerId,
    targetType: EParamAdvertiserTargetType.Category,
    channelType: convertChannelTypeToParam(campaign.channelType),
    enabled: orgType === EOrgType.Advertiser,
  })
  const categories =
    (categoriesData?.data as IPermittedCategoriesResponse)?.categories || []

  const getCategoryFloorPrice = (id: number) => {
    const category = categories.find((c) => c.id === id)

    return category?.floorPrice || 0
  }

  const campaignId = campaign?.id
  const adGroupId = adGroup?.id
  const hookFn = getTableAndChartCategoriesFn({
    orgType,
  })

  const getTableDataFn = hookFn.tableFn!

  const [sortBy, setSortBy] = useState<TCategoriesTableHeaders>()
  const [sortOrder, setSortOrder] = useState<EParamSortOrder>()
  const [tableData, setTableData] = useState<TReportTableDataV3>()

  const [errorUpdate, setErrorUpdate] = useState<TTranslationKey>()
  const [showUpdateErrorToast, setShowUpdateErrorToast] = useShowToast(false)

  useGetDefaultSortBy({
    sortBy,
    sortOrder,
    setSortBy,
    setSortOrder,
    orgType,
    channelType,
  })

  const {
    data: tableDataResponseObj,
    refetch,
    isFetching,
  } = getTableDataFn({
    retailerId,
    advertiserId,
    channelType,
    campaignId,
    adGroupId,
    sortBy,
    timeWindow,
    startDate,
    endDate,
    sortOrder,
    tzZone,
    skip: !!channelType,
    enabled: false,
  })

  const onSortByChange = (sortColumn?: TGlobalTables) => {
    setSortBy(sortColumn as TCategoriesTableHeaders)
  }

  const onSortOrderChange = (
    type: TReportingFilters['sortOrder'] = EParamSortOrder.Desc
  ) => {
    setSortOrder(type)
  }

  const tableDataResponse =
    tableDataResponseObj?.data.table || ({} as IReportCategoriesTable)

  const datasetKey =
    (tableDataResponse.datasetKey as TDatasetWhitelistKey) ||
    getCategoriesEDatasetKeyFromChannelTypeGlobal(channelType)

  const whitelistDataList = useGetDatasetKeyTableWhitelist({
    key: datasetKey,
  })

  const processTableDataResponse = (tableDataRes: IReportCategoriesTable) => {
    const responseHeaders = Array.from(
      new Set(tableDataRes.rows.map((row) => Object.keys(row)).flat())
    )

    const whitelistData = whitelistDataList
      .map((wData) => {
        const whitelistItem = responseHeaders.find(
          (header) => wData.header === header
        )
        if (whitelistItem) {
          return wData
        }
        return undefined
      })
      .filter((wData) => wData) as Array<TGlobalTableDataType>

    const headers = whitelistData.map((wData) => wData.header)

    const processedTableData: TReportTableDataV3 = {
      columnIdList: headers,
      columnNameList: whitelistData.map((wData) => wData.translatedMetricName),
      columnNameTooltipList: whitelistData.map(
        (wData) => wData.translatedTooltipMetricName
      ),
      columnSpanList: headers.map((header) =>
        header === EReportColumnHeader.Name ? 2 : 1
      ),
      rowList: [],
    }

    tableDataRes.rows.forEach((row) => {
      const dataRow: Array<ReactNode | ReactNode[]> = []
      headers.forEach((header) => {
        let dataCell: ReactNode | ReactNode[]
        const dataValue = row[header as TCategoriesTableHeaders]

        switch (header) {
          case EReportColumnHeader.Name:
            dataCell = <TableDataCellName dataValue={dataValue?.toString()} />
            break
          case EReportColumnHeader.Status:
            dataCell = (
              <TableDataCellStatusCategoryWithUpdate
                dataValue={dataValue?.toString() as EStatus}
                orgType={orgType}
                id={row.id}
                key={`${row.id}-${sortBy}-${sortOrder}`}
                setErrorUpdateStatus={setErrorUpdate}
                setShowUpdateStatusErrorToast={setShowUpdateErrorToast}
              />
            )
            break
          case EReportColumnHeader.Campaigns:
            dataCell = (
              <TableDataCellCampaigns dataValue={dataValue as Array<string>} />
            )
            break
          case EReportColumnHeader.Adgroups:
            dataCell = (
              <TableDataCellAdGroups dataValue={dataValue as Array<string>} />
            )
            break
          case EReportColumnHeader.BidPrice:
            dataCell = (
              <TableDataCellBidPrice
                orgType={orgType}
                dataValue={Number(dataValue)}
                dataId={row.id}
                floorPrice={getCategoryFloorPrice(row.id)}
                status={row.status}
                setErrorUpdate={setErrorUpdate}
                setShowUpdateErrorToast={setShowUpdateErrorToast}
              />
            )
            break
          case EReportColumnHeader.Impressions:
            dataCell = (
              <TableDataCellImpressions dataValue={Number(dataValue)} />
            )
            break
          case EReportColumnHeader.Clicks:
            dataCell = <TableDataCellClicks dataValue={Number(dataValue)} />
            break
          case EReportColumnHeader.Ctr:
            dataCell = <TableDataCellCtr dataValue={Number(dataValue)} />
            break
          case EReportColumnHeader.Costs:
            dataCell = <TableDataCellCosts dataValue={Number(dataValue)} />
            break
          case EReportColumnHeader.Carts:
            dataCell = <TableDataCellCarts dataValue={Number(dataValue)} />
            break
          case EReportColumnHeader.Purchases:
            dataCell = <TableDataCellPurchases dataValue={Number(dataValue)} />
            break
          case EReportColumnHeader.Revenue:
            dataCell = <TableDataCellRevenue dataValue={Number(dataValue)} />
            break
          case EReportColumnHeader.Roas:
            dataCell = <TableDataCellRoas dataValue={Number(dataValue)} />
            break
          case EReportColumnHeader.ShareOfVoice:
            dataCell = (
              <TableDataCellShareOfVoice dataValue={Number(dataValue)} />
            )
            break
          case EReportColumnHeader.WinRate:
            dataCell = <TableDataCellWinRate dataValue={Number(dataValue)} />
            break
          // SP Exclusive
          case EReportColumnHeader.Cpc:
            dataCell = <TableDataCellCpc dataValue={Number(dataValue)} />
            break
          case EReportColumnHeader.AvgRank:
            dataCell = <TableDataCellAvgRank dataValue={Number(dataValue)} />
            break
          case EReportColumnHeader.NewToBrandPurchases:
            dataCell = (
              <TableDataCellNewToBrandPurchases dataValue={Number(dataValue)} />
            )
            break
          case EReportColumnHeader.NewToBrandRevenue:
            dataCell = (
              <TableDataCellNewToBrandRevenue dataValue={Number(dataValue)} />
            )
            break
          // DA Exclusive
          case EReportColumnHeader.Frequency:
            dataCell = <TableDataCellFrequency dataValue={Number(dataValue)} />
            break
          case EReportColumnHeader.UniqueReach:
            dataCell = (
              <TableDataCellUniqueReach dataValue={Number(dataValue)} />
            )
            break
          case EReportColumnHeader.PvCarts:
            dataCell = <TableDataCellCarts dataValue={Number(dataValue)} />
            break
          case EReportColumnHeader.PvPurchases:
            dataCell = <TableDataCellPurchases dataValue={Number(dataValue)} />
            break
          case EReportColumnHeader.PvRevenue:
            dataCell = <TableDataCellRevenue dataValue={Number(dataValue)} />
            break
          case EReportColumnHeader.Cpm:
            dataCell = <TableDataCellCpm dataValue={Number(dataValue)} />
            break

          default:
            dataCell = undefined
            break
        }

        if (dataCell) {
          dataRow.push(dataCell)
        }
      })

      processedTableData.rowList.push(dataRow)
    })

    return processedTableData
  }

  useEffect(() => {
    if (startDate && endDate && sortBy && sortOrder) {
      refetch()
    }
  }, [startDate, endDate, sortBy, sortOrder])

  useEffect(() => {
    if (refreshTable) {
      refetch()
      dispatch(setRefreshTable({ refreshTable: false }))
    }
  }, [refreshTable])

  useEffect(() => {
    if (Object.keys(tableDataResponse).length > 0) {
      setTableData(processTableDataResponse(tableDataResponse))
      onTableDataChange(
        tableDataResponse as ISPReportCategoriesTable & IDAReportCategoriesTable
      )
    }
  }, [tableDataResponse])

  return (
    <>
      {tableData?.rowList?.length &&
        tableData?.rowList?.length > 0 &&
        !isFetching && (
          <>
            {tableData?.rowList && (
              <ReportTableV3
                {...tableData}
                sortBy={sortBy}
                sortOrder={sortOrder}
                setSortBy={onSortByChange}
                setSortOrder={onSortOrderChange}
              />
            )}
          </>
        )}
      {tableData?.rowList?.length === 0 && (
        <StyledImage
          fluid
          src={getPlaceholderImage(EEntityType.AdGroupKeyword, orgType)}
          alt="home_placeholder"
          cursor="default"
        />
      )}

      <ErrorToast
        show={showUpdateErrorToast}
        onClose={() => {
          setShowUpdateErrorToast(false)
          setErrorUpdate(undefined)
        }}
        translationKey={errorUpdate}
      />

      {isFetching && <TablePreloader />}
    </>
  )
}
